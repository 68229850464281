import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppFrame } from './AppFrame';
import { NotFound } from './NotFound';
import { HomePage } from './HomePage';
import { DebtorsPage } from './DebtorsPage';

export function Router() {
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AppFrame drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
          }
        >
          <Route
            path=""
            element={
              <HomePage drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            }
          />
          <Route path="/debtors" element={<DebtorsPage />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
