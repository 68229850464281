import React from 'react';

import SalesBranchPicker from './SalesOrderFields/SalesBranchPicker';
import StockLocationPicker from './SalesOrderFields/StockLocationPicker';
import ShippingProviderPicker from './SalesOrderFields/ShippingProviderPicker';
import OrderActions from './OrderActions';
import AddressDisplay from './SalesOrderFields/AddressDisplay';
import {
  DebtorPicker,
  useOrderDebtorPicker,
} from './SalesOrderFields/DebtorPicker';
import CustomerReference from './SalesOrderFields/CustomerReference';
import EmailOverride from './SalesOrderFields/EmailOverride';
import InternalReference from './SalesOrderFields/InternalReference';
import Narrative from './SalesOrderFields/Narrative';
import Instructions from './SalesOrderFields/Instructions';
import CustomerName from './SalesOrderFields/CustomerName';
import CustomerPhoneNumber from './SalesOrderFields/CustomerPhoneNumber';
import DatePaymentDue from './SalesOrderFields/DatePaymentDue';

import {
  OrderQuery,
  useProvideDefaultStockLocationMutation,
  WipStatus,
  WipType,
} from '../generated/graphql';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import OnHoldCheckBox from './SalesOrderFields/OnHoldCheckBox';
import GstInclusiveCheckBox from './SalesOrderFields/GstInclusiveCheckBox';
import { STATE_UNKNOWN } from '../services/apollo/setup';

export interface OrderHeaderProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const OrderHeaderStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr;
  padding: 1em 0 1em 1em;

  h6 {
    font-size: 1rem;
  }

  & .debtor {
    grid-column: 1 / span 2;
    margin-bottom: 2em;
  }

  & > .text {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, auto));
    margin-bottom: 2em;
  }

  & > .shippingInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #91919121;
    padding: 25px 10px;
  }
`;

export const OrderHeader: React.FC<OrderHeaderProps> = ({ order }) => {
  const frozen = [WipStatus.Rejected, WipStatus.Sent].includes(order.status);
  const dateToShow = new Date(order.dateProcessed || order.dateSubmitted);

  const debtorPicker = useOrderDebtorPicker(order);

  const [provideDefaultStockLocation] =
    useProvideDefaultStockLocationMutation();
  const setDefaultStockLocation = (id: string | null) => {
    provideDefaultStockLocation({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          stockLocationId: id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideDefaultStockLocation: {
          __typename: 'WipSalesOrderProvideDefaultStockLocationPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                  {
                    __typename: 'FieldModification',
                    field: 'defaultStockLocationId',
                    formerValue: order.defaultStockLocationId,
                    date: new Date(),
                  },
                ])
                : [],
            defaultStockLocationId: id,
            defaultStockLocation: {
              __typename: 'StockLocation',
              name: '',
            },
          },
        },
      },
    });
  };

  return (
    <div>
      <OrderHeaderStyle>
        <div className="text">
          <div className="debtor">
            <DebtorPicker
              openOnFocus={!order.customer}
              disabled={
                order.orderDataFixed ||
                order.status === WipStatus.Rejected ||
                order.status === WipStatus.Sent
              }
              value={order.customer ?? null}
              onChange={debtorPicker.onChange}
            />
          </div>
          <CustomerReference order={order} frozen={frozen} />

          <div>
            <Typography variant="h6">
              Date
              {order.status === WipStatus.Rejected
                ? ' Rejected'
                : order.status === WipStatus.Sent
                  ? ' Posted'
                  : ''}
            </Typography>
            <Typography>
              {dateFormat(dateToShow, 'dd/mm/yyyy')}
              <br />
              {dateFormat(dateToShow, 'h:MM:ss TT')}
            </Typography>
          </div>
          <SalesBranchPicker
            order={order}
            fixedValue={frozen ? order.salesBranch?.name || '(invalid)' : null}
            modifications={order.modifications.filter(
              (x) => x.field === 'salesBranchId',
            )}
          />
          <StockLocationPicker
            stockLocationId={order.defaultStockLocationId || null}
            setStockLocationId={setDefaultStockLocation}
            blankText="(per line)"
            fixedValue={
              frozen ? order.defaultStockLocation?.name || '(per line)' : null
            }
            modifications={order.modifications.filter(
              (x) => x.field === 'defaultStockLocationId',
            )}
          />

          <EmailOverride order={order} />
          <InternalReference order={order} />
          <Narrative order={order} />
        </div>
        <div className="shippingInfo">
          <AddressDisplay
            order={order}
            modifications={order.modifications.filter(
              (x) => x.field === 'deliveryAddress',
            )}
          />
          <DatePaymentDue order={order} />
          <ShippingProviderPicker
            order={order}
            fixedValue={
              frozen ? order.shippingProvider?.name || '(none)' : null
            }
            modifications={order.modifications.filter(
              (x) => x.field === 'shippingProviderId',
            )}
          />
          <CustomerName order={order} />
          <CustomerPhoneNumber order={order} />
          <Instructions order={order} />
        </div>
      </OrderHeaderStyle>
      <div>
        <OnHoldCheckBox order={order} />
        <GstInclusiveCheckBox order={order} />
        <OrderActions order={order} />
      </div>
    </div>
  );
};
