import { useState } from 'react';
import { OrderSideDrawer } from './OrderSideDrawer';
import { OrderView } from './OrderView';
import { useCreateOrderMutation } from '../generated/graphql';
import Swal from 'sweetalert2';
import { PageInner } from './PageInner';

export function HomePage({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}) {
  const [selectedOrderId, selectOrder] = useState<string | null>(null);
  const [createOrder] = useCreateOrderMutation({
    refetchQueries: ['OrderList'],
    awaitRefetchQueries: true,
  });

  const handleNewSalesOrder = async () => {
    const res = await createOrder();

    if (res.errors || !res.data) {
      Swal.fire({
        title: 'Failed to create new Sales Order, please contact IT.',
        icon: 'error',
        showConfirmButton: true,
      });
    }

    selectOrder(res.data?.wipSalesOrderCreate.order.id!);
  };

  return (
    <>
      <OrderSideDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        selectedOrderId={selectedOrderId}
        selectOrder={selectOrder}
        handleNewSalesOrder={handleNewSalesOrder}
      />
      <PageInner drawerOpen={drawerOpen}>
        {selectedOrderId ? (
          <OrderView id={selectedOrderId} />
        ) : (
          <div>Select an order?</div>
        )}
      </PageInner>
    </>
  );
}
