import { Card, CardContent, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { useState } from 'react';
import { SalesOrderStatus, useRecentOrdersQuery } from '../generated/graphql';
import { PageInner } from './PageInner';
import {
  DebtorPicker,
  DebtorPickerDetails,
} from './SalesOrderFields/DebtorPicker';

const TAKE = 30;

export function DebtorsPage() {
  const [debtor, setDebtor] = useState<DebtorPickerDetails | null>(null);

  const { data } = useRecentOrdersQuery({
    fetchPolicy: 'network-only',
    skip: debtor == null,
    variables: {
      customerId: debtor?.id!,
      take: TAKE,
    },
  });

  return (
    <PageInner style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '500px' }}>
        <Typography variant="h2" fontSize="2rem">
          Recent Orders
        </Typography>
        <div style={{ width: '100%', margin: '2em 0' }}>
          <DebtorPicker value={debtor} onChange={(_, v) => setDebtor(v)} />
          <Typography variant="caption">
            Showing most recent {TAKE} orders from this customer
          </Typography>
        </div>
        {data?.salesOrders.map((order) => (
          <Card key={order.id} sx={{ padding: '1em', marginBottom: '1em' }}>
            <CardContent>
              <Typography variant="h6">
                <strong>Reference:</strong> {order.customerReference}
              </Typography>
              <Typography>
                <strong>Order Date:</strong>{' '}
                {dateFormat(new Date(order.dateOrdered), 'dd/mm/yyyy')}
              </Typography>
              <Typography>
                <strong>Status:</strong> {statusToString(order.status)}
              </Typography>
              <Typography>
                <strong>Total (GST Excl.):</strong> $
                {order.totalWithoutTax.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </PageInner>
  );
}

function statusToString(status: SalesOrderStatus) {
  const map: Record<SalesOrderStatus, string> = {
    [SalesOrderStatus.NotProcessed]: 'Not Processed',
    [SalesOrderStatus.PartiallyProcessed]: 'Partially Processed',
    [SalesOrderStatus.FullyProcessed]: 'Fully Processed',
    [SalesOrderStatus.Quote]: 'Quote',
    [SalesOrderStatus.Other]: 'Other',
  };
  return map[status] ?? `Unknown Status (${status})`;
}
