import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole, Offline } from '@sentry/integrations';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import './global.css';
import App from './App';
import { releaseProfile, env } from './runtime-environment';
import { createBrowserHistory } from 'history';
import { Error } from './components/Error';
import 'sweetalert2/dist/sweetalert2.css';

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://40dcab030fbc444a8234f5121eb33c5b@o925760.ingest.sentry.io/5949950',
    release: `sales-handler@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Offline(),
    ],
    tracesSampleRate: 0.05,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={<Error fullscreen errorText="The app crashed." />}
      showDialog
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
