import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Error } from '../Error';
import { COLOR_BAD } from '../../constants';
import { DEBOUNCE_MS } from '../../util';

import {
  useSalesBranchesQuery,
  OrderQuery,
  useProvideCustomerReferenceMutation,
  useFindDuplicateCustomerReferenceQuery,
} from '../../generated/graphql';
import {
  Typography,
  LinearProgress,
  FormControl,
  TextField,
  FormHelperText,
} from '@mui/material';
import { optimisticMutation } from './optimistic';

export interface CustomerReferenceProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
  frozen: boolean;
}

const CustomerReference: React.FC<CustomerReferenceProps> = ({
  order,
  frozen,
}) => {
  const [current, setCurrent] = useState<string | null>(null);
  const { loading, error, data } = useSalesBranchesQuery({
    fetchPolicy: 'cache-first',
  });

  const { data: duplicatesData } = useFindDuplicateCustomerReferenceQuery({
    variables: { reference: order.customerReference! },
    skip: order.customerReference == null || order.customerReference === '',
  });

  const [provideCustomerReference] = useProvideCustomerReferenceMutation();
  const setCustomerReference = (value: string) => {
    provideCustomerReference(
      optimisticMutation({
        mutationName: 'wipSalesOrderProvideCustomerReference',
        key: 'customerReference',
        value,
        order,
      }),
    );
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setCustomerReference(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.toUpperCase();
    setCurrent(val);
    doMutation(val);
  };

  if (loading) return <LinearProgress />;
  if (error || !data) return <Error />;

  const duplicates =
    duplicatesData?.duplicates.filter(
      (x) => x.id !== order.resultingOrder?.id,
    ) ?? [];
  const isDuplicate = duplicates.length > 0;

  return (
    <div>
      <Typography variant="h6">Customer Order Number</Typography>
      <FormControl style={{ width: 150 }} variant="standard">
        <TextField
          disabled={frozen || order.orderDataFixed}
          tabIndex={1}
          value={current ?? order.customerReference}
          onChange={onChange}
          error={
            (!order.customerReference && current === null) || current === ''
          }
          variant="standard"
          required
        />
      </FormControl>
      {isDuplicate && (
        <FormHelperText
          style={{
            color: COLOR_BAD,
          }}
        >
          This customer order number already exists on:
          <br />
          {duplicates.map((x) => x.id).join(', ')}
        </FormHelperText>
      )}
      {!order.customerReference && (
        <FormHelperText style={{ color: COLOR_BAD }}>This field is required</FormHelperText>
      )}

    </div>
  );
};

export default CustomerReference;
