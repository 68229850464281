import React from 'react';
import apollo from './services/apollo';
import { ApolloProvider } from '@apollo/client';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import {
  authEnabled,
  getAuthProvider,
  authenticationRequest,
} from './services/auth';
import { InteractionType } from '@azure/msal-browser';
import { CssBaseline, LinearProgress } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { Router } from './components/Router';

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#375954',
    },
    secondary: {
      main: '#999',
    },
    error: {
      main: '#d35f5f',
    },
    warning: {
      main: '#e9822e',
    },
  },
});

const MicrosoftAuth: React.FC = ({ children }) => {
  if (!authEnabled) {
    return <>{children}</>;
  }

  return (
    <MsalProvider instance={getAuthProvider()}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authenticationRequest}
      >
        {() => {
          const instance = getAuthProvider();
          const accountInfo = instance.getAllAccounts()[0];

          if (accountInfo) {
            return children;
          }

          return <LinearProgress />;
        }}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const App: React.FC = () => (
  <MicrosoftAuth>
    <ApolloProvider client={apollo()}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={materialOverrides}>
          <CssBaseline />
          <Router />
        </MuiThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  </MicrosoftAuth>
);

export default App;
