import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import {
  useProvideCustomerNameMutation,
  OrderQuery,
  WipStatus,
} from '../../generated/graphql';
import { Typography, FormControl, TextField } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface CustomerNameProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const CustomerName: React.FC<CustomerNameProps> = ({ order }) => {
  const [current, setCurrent] = useState<string | null>(null);

  const [provideCustomerName] = useProvideCustomerNameMutation();
  const setCustomerName = (value: string) => {
    provideCustomerName({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          customerName: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideCustomerName: {
          __typename: 'WipSalesOrderProvideCustomerNamePayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            customerName: value,
          },
        },
      },
    });
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setCustomerName(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrent(val);
    doMutation(val);
  };

  return (
    <div>
      <Typography variant="h6">Customer Name</Typography>
      <FormControl style={{ width: 250 }} variant="standard">
        <TextField
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          multiline={true}
          placeholder={order.customer?.name}
          value={current ?? (order.customerName || '')}
          onChange={onChange}
          variant="standard"
        />
      </FormControl>
    </div>
  );
};

export default CustomerName;
