import React, { useEffect, useState } from 'react';

import { Loading } from './Loading';
import { Error } from './Error';
import { OrderHeader } from './OrderHeader';
import { OrderTable } from './OrderTable';
import Documents from './OriginalSources/Documents';
import { useStockItemPricing } from '../util';

import { FetchPolicy, NetworkStatus } from '@apollo/client';
import {
  useOrderQuery,
  useSalesBranchesQuery,
  useStockLocationsQuery,
} from '../generated/graphql';

// 20 mins, comfortably inside expiry window for Azure document urls
const INVALIDATE_AFTER = 20 * 60 * 1000;

const queriedCache: Record<string, Date> = {};

export const OrderView: React.FC<{ id: string }> = ({ id }) => {
  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('cache-first');
  const orderQuery = useOrderQuery({
    variables: { id },
    fetchPolicy,
  });

  useEffect(() => {
    const lastFetched = queriedCache[id];
    if (!lastFetched) {
      queriedCache[id] = new Date();
      setFetchPolicy('cache-first');
      return;
    }

    if (Date.now() - lastFetched.getTime() > INVALIDATE_AFTER) {
      queriedCache[id] = new Date();
      setFetchPolicy('network-only');
    }
  }, [id]);

  const branchesQuery = useSalesBranchesQuery({
    fetchPolicy: 'cache-first',
  });

  const stockLocationsQuery = useStockLocationsQuery({
    fetchPolicy: 'cache-first',
  });

  const order = orderQuery.data?.wipSalesOrder;
  const pricingQuery = useStockItemPricing(order ?? null);

  const queries = [orderQuery, branchesQuery, stockLocationsQuery];

  if (
    orderQuery.networkStatus === NetworkStatus.refetch ||
    ((queries.find((x) => x.loading) || pricingQuery.loading) &&
      pricingQuery.networkStatus !== NetworkStatus.setVariables)
  ) {
    return <Loading fullscreen />;
  }

  if (queries.find((x) => x.error) || !orderQuery.data?.wipSalesOrder) {
    return <Error fullscreen />;
  }

  return (
    <div>
      <OrderHeader order={order!} />
      <OrderTable order={order!} />
      <Documents documents={order!.originalSources} />
    </div>
  );
};
