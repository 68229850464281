import React, { useState, useEffect } from 'react';
import { useThrottle } from 'react-use';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import {
  useProvideLineStockCodeMutation,
  useStockItemsFuzzySearchQuery,
  useProvideLineStockItemMutation,
  WipStatus,
  WipType,
} from '../../generated/graphql';
import { Line } from '../OrderTableRow';
import { DEBOUNCE_MS } from '../../util';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface StockItemSelectorProps {
  line: Line;
  orderId: string;
  debtorId: string | null;
  orderStatus: WipStatus;
  orderType: WipType;
  orderDataFixed: boolean;
  autoFocused: boolean;
}

const StockItemSelector: React.FC<StockItemSelectorProps> = ({
  line,
  orderId,
  debtorId,
  orderStatus,
  orderType,
  orderDataFixed,
  autoFocused,
}) => {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<string | null>(null);
  const [provideStockCode] = useProvideLineStockCodeMutation();
  const [provideStockItem] = useProvideLineStockItemMutation();
  const throttledSearch = useThrottle(current, 700);

  const { previousData, data, loading } = useStockItemsFuzzySearchQuery({
    variables: {
      input: {
        description: throttledSearch,
        take: 10,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !throttledSearch,
  });

  const onInput = (e: any) => {
    if (e.target.value) {
      const value = e.target.value.toUpperCase();

      setCurrent(value);
      update(value);
    }
  };

  const setStockItem = async (value: string) => {
    await provideStockItem({
      variables: {
        input: {
          stockCode: value,
          lineId: line.id,
          orderId,
          debtorId: debtorId!,
          state: STATE_UNKNOWN,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineStockItem: {
          __typename: 'WipSalesOrderProvideLineStockItemPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            stockCode: value,
            stockItem: null,
            // Assume location won't change, but it may well do
            stockLocationId: line.stockLocationId,
            stockLocation: null,
          },
        },
      },
    });
  };

  const setStockCode = async (value: string) => {
    await provideStockCode({
      variables: {
        input: {
          stockCode: value,
          lineId: line.id,
          orderId,
          state: STATE_UNKNOWN,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineStockCode: {
          __typename: 'WipSalesOrderProvideLineStockCodePayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            stockCode: value,
            stockItem: null,
            // Assume location won't change, but it may well do
            stockLocationId: line.stockLocationId,
            stockLocation: null,
            modifications:
              orderType !== WipType.Manual
                ? line.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'stockCode',
                      formerValue: line.stockCode,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const update = useDebouncedCallback(async (value: string) => {
    setStockCode(value);
  }, DEBOUNCE_MS);

  const handleSelectOption = (option: any) => {
    if (debtorId) {
      setCurrent(option.stockCode);
      setStockItem(option.stockCode);
    }
  };

  useEffect(
    () => () => {
      update.flush();
    },
    [update],
  );

  return (
    <>
      <Autocomplete
        disabled={
          orderDataFixed ||
          orderStatus === WipStatus.Sent ||
          orderStatus === WipStatus.Rejected ||
          !debtorId
        }
        value={(current ?? line.stockCode) as any}
        style={{ width: '20em' }}
        onInput={(e) => {
          onInput(e);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_, value, reason: any) => {
          if (reason === 'selectOption') {
            handleSelectOption(value);
          }
        }}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : `${option.stockCode} | ${option.description}` ?? ''
        }
        options={(data ?? previousData)?.stockItemsFuzzySearch ?? []}
        loading={loading}
        autoHighlight
        autoComplete
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Stock Code"
            autoFocus={autoFocused}
            value={current}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            variant="standard"
          />
        )}
      />
    </>
  );
};

export default StockItemSelector;
