import React, { useState } from 'react';

import { Error } from '../Error';
import { ModificationDisplay } from '../ModificationDisplay';
import { InputUnderline } from '../InputUnderline';

import {
  useShippingProvidersQuery,
  FieldModification,
  OrderQuery,
  useProvideShippingProviderMutation,
  WipType,
} from '../../generated/graphql';
import { Typography, Select, MenuItem, LinearProgress } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface ShippingProviderPickerProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
  fixedValue: string | null;
  modifications: FieldModification[];
}

const ShippingProviderPicker: React.FC<ShippingProviderPickerProps> = ({
  order,
  fixedValue,
  modifications,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { loading, error, data } = useShippingProvidersQuery({
    fetchPolicy: 'cache-first',
  });

  const [provideShippingProvider] = useProvideShippingProviderMutation();
  const setShippingProviderId = (id: string) => {
    provideShippingProvider({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          shippingProviderId: id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideShippingProvider: {
          __typename: 'WipSalesOrderProvideShippingProviderPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'shippingProviderId',
                      formerValue: order.shippingProviderId,
                      date: new Date(),
                    },
                  ])
                : [],
            shippingProviderId: id,
            shippingProvider: {
              __typename: 'ShippingProvider',
              name: '',
            },
          },
        },
      },
    });
  };

  if (loading) return <LinearProgress />;
  if (error || !data) return <Error />;

  const shippingProviders = data.shippingProviders;
  return (
    <ModificationDisplay
      modifications={modifications}
      processValue={(id: string | null) =>
        id === null
          ? null
          : shippingProviders.find((x) => x.id === id)?.name ?? '??'
      }
      tooltipOpen={tooltipOpen}
    >
      <InputUnderline enable={modifications.length > 0}>
        <Typography variant="h6">Shipping Provider</Typography>
        {fixedValue ? (
          <Typography>{fixedValue}</Typography>
        ) : (
          <Select
            style={{ width: 150 }}
            value={order.shippingProviderId || -1}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            onMouseDown={() => setTooltipOpen(false)}
            onChange={(e) => setShippingProviderId(e.target.value as string)}
            variant="standard"
          >
            <MenuItem>(none)</MenuItem>
            {shippingProviders.map((shippingProvider) => (
              <MenuItem key={shippingProvider.id} value={shippingProvider.id}>
                {shippingProvider.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </InputUnderline>
    </ModificationDisplay>
  );
};

export default ShippingProviderPicker;
