import React, { useEffect, useReducer } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import {
  useProvideEmailOverrideMutation,
  OrderQuery,
  WipStatus,
} from '../../generated/graphql';

import { Typography, FormControl, TextField } from '@mui/material';
import { optimisticMutation } from './optimistic';

export interface EmailOverrideProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

enum EmailActionType {
  SetEmail = 'SET_EMAIL',
}

interface EmailState {
  current: string | null;
  emailIsValid: boolean;
}

type EmailAction =
  | { type: EmailActionType.SetEmail; payload: string | null };

const emailReducer = (state: EmailState, action: EmailAction): EmailState => {
  switch (action.type) {
    case EmailActionType.SetEmail:
      return { ...state, current: action.payload, emailIsValid: validateEmail(action.payload) };
    // other actions can be added here
    default:
      return state;
  }
};

const EMAIL_TEST =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email: string | null) => {
  return email ? EMAIL_TEST.test(email) : true;
}

const EmailOverride: React.FC<EmailOverrideProps> = ({ order }) => {
  const initialEmail: string | null = order?.emailOverride ?? order?.customer?.email ?? null;
  const [state, dispatch] = useReducer(emailReducer, {
    current: initialEmail,
    emailIsValid: validateEmail(initialEmail)
  });

  useEffect(() => {
    dispatch({ type: EmailActionType.SetEmail, payload: initialEmail });
  }, [initialEmail]);

  const [provideEmailOverride] = useProvideEmailOverrideMutation();
  const setEmailOverride = (value: string) => {
    provideEmailOverride(
      optimisticMutation({
        mutationName: 'wipSalesOrderProvideEmailOverride',
        key: 'emailOverride',
        value,
        order,
      }),
    );
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setEmailOverride(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    dispatch({ type: EmailActionType.SetEmail, payload: val });
    doMutation(val);
  };

  return (
    <div>
      <Typography variant="h6">Email Override</Typography>
      <FormControl style={{ width: 150 }} variant="standard">
        <TextField
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          value={state.current ?? ''}
          onChange={onChange}
          error={!state.emailIsValid || (!state.current && !order.customer?.email && !order.emailOverride)}
          helperText={
            !state.emailIsValid ? "Not a valid email address" :
              (!state.current && !order.customer?.email && !order.emailOverride) ? "This field is required" :
                null}
          variant="standard"
          required
        />
      </FormControl>


    </div>
  );
};

export default EmailOverride;
