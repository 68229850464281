import { DRAWER_WIDTH } from '../constants';

export function PageInner({
  drawerOpen,
  children,
  style,
}: {
  drawerOpen?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        padding: '3em',
        marginTop: 64,
        marginLeft: drawerOpen ? DRAWER_WIDTH : 0,
        height: `calc(100vh - 64px)`,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
