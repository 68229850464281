import React from 'react';

import { Error } from '../Error';

import {
  useDebtorsQuery,
  WipType,
  OrderQuery,
  useProvideCustomerIdMutation,
  useProvideDeliveryAddressMutation,
  DebtorsQuery,
} from '../../generated/graphql';
import { Autocomplete, FormHelperText, TextField, Typography } from '@mui/material';
import { matchSorter } from 'match-sorter';
import Swal from 'sweetalert2';
import { STATE_UNKNOWN } from '../../services/apollo/setup';
import { COLOR_BAD } from "../../constants";

export type DebtorPickerDetails = DebtorsQuery['debtors'][0];

export interface DebtorPickerProps {
  openOnFocus?: boolean;
  disabled?: boolean;
  value: DebtorPickerDetails | null;
  onChange: (
    e: React.SyntheticEvent,
    value: DebtorPickerDetails | null,
  ) => void;
}

const filterOptions = (
  options: DebtorPickerDetails[],
  { inputValue }: { inputValue: string },
) => matchSorter(options, inputValue, { keys: ['name'] });

export const DebtorPicker: React.FC<DebtorPickerProps> = ({
  openOnFocus,
  disabled,
  value,
  onChange,
}) => {
  const { error, data } = useDebtorsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Error />;

  const debtors = data?.debtors;
  return (
    <div>
      <Autocomplete<DebtorPickerDetails>
        tabIndex={1}
        autoComplete
        openOnFocus={openOnFocus}
        autoHighlight
        disabled={disabled}
        onChange={onChange}
        options={debtors || []}
        getOptionLabel={(x) => x.name ?? ''}
        style={{
          width: '90%',
          display: 'flex',
          alignItems: 'center',
        }}
        size="small"
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField {...params} label="Debtor" variant="outlined" autoFocus />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.id}
            style={{
              color: option.onStopCredit ? 'red' : 'unset',
              display: 'block',
            }}
          >
            <div>{option.name}</div>
            {option.onStopCredit && (
              <Typography variant="caption">On stop credit</Typography>
            )}
          </li>
        )}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      {value?.onStopCredit && (
        <div style={{ color: 'red' }}>
          <Typography variant="caption" style={{ fontSize: '1.1rem' }}>
            <strong>Debtor on stop credit</strong>
          </Typography>
        </div>
      )}
      {value?.alert && <div style={{ color: 'red' }}>{value.alert}</div>}
      {!value && (
        <FormHelperText style={{ color: COLOR_BAD }}>This field is required</FormHelperText>
      )}
    </div>
  );
};

export function useOrderDebtorPicker(
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>,
) {
  const [provideCustomerId] = useProvideCustomerIdMutation();
  const [provideDeliveryAddress] = useProvideDeliveryAddressMutation();
  const setCustomerId = (
    customerId: string | null,
    deliveryAddress: string[] | null,
  ) => {
    provideCustomerId({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          customerId: customerId,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideCustomerId: {
          __typename: 'WipSalesOrderProvideCustomerIdPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            customer: order.customer,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'customerId',
                      formerValue: order.customerId,
                      date: new Date(),
                    },
                  ])
                : [],
            customerId: customerId,
          },
        },
      },
    });

    provideDeliveryAddress({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          deliveryAddress: deliveryAddress,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideDeliveryAddress: {
          __typename: 'WipSalesOrderProvideDeliveryAddressPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            deliveryAddress: deliveryAddress,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'deliveryAddress',
                      formerValue: order.deliveryAddress,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const onChange = (e: React.SyntheticEvent, v: DebtorPickerDetails | null) => {
    if (order.customerId == null || v == null) {
      setCustomerId(
        v ? v.id : null,
        v ? v.deliveryAddress.map((addr) => addr.toUpperCase()) : null,
      );
    } else {
      Swal.fire({
        title:
          'Some fields may change based on new defaults. Pricing may become incorrect if you change the debtor. You may see new price suggestions on lines to fix this. Are you sure you want to proceed?',
        showCancelButton: true,
        confirmButtonText: `OK`,
      }).then((result) => {
        if (result.isConfirmed) {
          setCustomerId(
            v ? v.id : null,
            v ? v.deliveryAddress.map((addr) => addr.toUpperCase()) : null,
          );
        } else {
          e.stopPropagation();
        }
      });
    }
  };

  return { onChange };
}
