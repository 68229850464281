import { OrderQuery, useStockItemPricingQuery } from './generated/graphql';

export const DEBOUNCE_MS = 500;

export const isUnset = <T extends {}>(x: T | null | undefined) =>
  x === null || x === undefined;

export function useStockItemPricing(
  order: OrderQuery['wipSalesOrder'],
  notifyOnNetworkStatusChange = false,
) {
  return useStockItemPricingQuery({
    fetchPolicy: 'cache-first',
    skip: !order?.customerId,
    notifyOnNetworkStatusChange,
    variables: order?.customerId
      ? {
          input: order.lines.map((x) => ({
            debtorId: order.customerId || '',
            orderQuantity: x.quantityOrdered ?? 0,
            stockCode: x.stockCode ?? '',
            taxType: order.taxType,
          })),
        }
      : null!,
  });
}
