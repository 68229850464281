import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import {
  useProvideDatePaymentDueMutation,
  OrderQuery,
  WipType,
} from '../../generated/graphql';

export interface DatePaymentDueProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const DatePaymentDue: React.FC<DatePaymentDueProps> = ({ order }) => {
  const [date, setDate] = useState<Date | null>(
    order.datePaymentDue ? new Date(order.datePaymentDue) : null,
  );

  const [provideDatePaymentDue] = useProvideDatePaymentDueMutation();
  const setDatePaymentDue = (value: Date | null) => {
    provideDatePaymentDue({
      variables: {
        input: {
          state: order.state,
          orderId: order.id,
          datePaymentDue: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideDatePaymentDue: {
          __typename: 'WipSalesOrderProvideDatePaymentDuePayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: 'unknown',
            status: order.status,
            datePaymentDue: value,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'datePaymentDue',
                      formerValue: order.datePaymentDue,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const onDateChange = (date: Date | null) => {
    setDate(date);
    setDatePaymentDue(date);
  };

  return (
    <div>
      <Typography variant="h6">Pick Due Date</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={date}
          onChange={(d) => onDateChange(d)}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePaymentDue;
