import {
  WipType,
  WipStatus,
  WipSalesOrder,
  FieldModification,
} from '../../generated/graphql';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export function optimisticMutation<
  TUpdate extends keyof WipSalesOrder,
  TMutation extends string,
>({
  mutationName,
  key,
  value,
  order,
}: {
  mutationName: TMutation;
  key: TUpdate;
  value: WipSalesOrder[TUpdate];
  order: Pick<
    WipSalesOrder,
    'id' | 'state' | 'status' | 'type' | 'modifications' | TUpdate
  >;
}) {
  const mutResult = {
    __typename: `${mutationName[0].toUpperCase()}${mutationName.slice(
      1,
    )}Payload` as PayloadType,
    error: null,
    order: {
      __typename: 'WipSalesOrder',
      id: order.id,
      state: STATE_UNKNOWN,
      status: order.status,
      [key]: value,
      modifications:
        order.type !== WipType.Manual
          ? order.modifications.concat([
              {
                __typename: 'FieldModification',
                field: key,
                formerValue: order[key],
                date: new Date(),
              },
            ])
          : [],
    } as OptimisticOrder,
  } as const;

  return {
    variables: {
      input: {
        state: STATE_UNKNOWN,
        orderId: order.id,
        [key]: value,
      } as Input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      [mutationName]: mutResult,
    } as Optimistic,
  };

  type PayloadType = `${Capitalize<TMutation>}Payload`;
  type OptimisticOrder = {
    __typename: 'WipSalesOrder';
    id: string;
    state: typeof STATE_UNKNOWN;
    status: WipStatus;
    modifications: FieldModification[];
  } & Pick<WipSalesOrder, TUpdate>;
  type Optimistic = { __typename: 'Mutation' } & Record<
    TMutation,
    typeof mutResult
  >;
  type Input = { state: string; orderId: string } & Pick<
    WipSalesOrder,
    TUpdate
  >;
}
