import React, { useState } from 'react';
import styled from 'styled-components';

import { EditableDisplay } from './EditableDisplay';
import StockItemSelector from './SalesOrderFields/StockItemSelector';
import StockLocationPicker from './SalesOrderFields/StockLocationPicker';
import ManualOrderLineUnitPrice from './SalesOrderFields/ManualOrderLineUnitPrice';
import ManualOrderLineQuantity from './SalesOrderFields/ManualOrderLineQuantity';
import { COLOR_INFO_GREEN, COLOR_BAD } from '../constants';
import { SimpleActionButton } from './OrderActions';
import { isUnset } from '../util';

import {
  OrderQuery,
  useProvideLineDescriptionMutation,
  WipStatus,
  useProvideLineQuantityMutation,
  useProvideLineUnitPriceMutation,
  useProvideLineStockLocationMutation,
  useRemoveLineFromOrderMutation,
  useRestoreLineOnOrderMutation,
  WipType,
  AddLineToOrderMutationFn,
} from '../generated/graphql';

import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Button,
  Tooltip,
  FormHelperText,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { STATE_UNKNOWN } from '../services/apollo/setup';

export type Line = Exclude<
  OrderQuery['wipSalesOrder'],
  null | undefined
>['lines'][0];

interface OrderTableRowProps {
  orderId: string;
  debtorId: string | null;
  state: string;
  orderStatus: WipStatus;
  orderDataFixed: boolean;
  defaultStockLocationId?: string | null;
  frozen: boolean;
  orderType: WipType;
  line: Line;
  financialUnitPrice: number | null;
  addLine: () => ReturnType<AddLineToOrderMutationFn>;
  lineNum: number;
  totalLines: number;
  addedLine: boolean;
}

const FirstRow = styled(TableRow)`
  &.suppressed {
    filter: opacity(40%);
  }

  &.hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  td {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SecondRow = styled(TableRow)`
  & > td {
    padding-top: 0px;
  }

  &.hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  &.suppressed.hover {
    background-color: rgba(0, 0, 0, 0.008);
  }

  &.suppressed > td > * {
    filter: opacity(40%);
  }

  p {
    font-size: 0.75rem;
  }
`;

const OrderTableRow: React.FC<OrderTableRowProps> = ({
  orderId,
  debtorId,
  orderStatus,
  orderDataFixed,
  defaultStockLocationId,
  financialUnitPrice,
  line,
  orderType,
  addLine,
  lineNum,
  totalLines,
  addedLine,
  frozen: frozen_,
}) => {
  const [hover, setHover] = useState(false);
  const [currentQuantity, setCurrentQuantity] = useState<string | null>(null);
  const [currentUnitPrice, setCurrentUnitPrice] = useState<string | null>(null);

  const frozen = frozen_ || line.suppressed;

  const badPrice = financialUnitPrice !== line.unitPrice || !financialUnitPrice;
  const { stockItem } = line;
  const idealQuantity =
    stockItem &&
    calculateIdealQuantity(
      line.quantityOrdered,
      stockItem.minimumOrderQuantity,
      stockItem.quantityMultiplier,
    );

  const handleTabNewLine = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab' && totalLines === lineNum + 1) {
      addLine();
    }
  };

  const quantityTooltip =
    (stockItem &&
      [
        stockItem.minimumOrderQuantity &&
          `MOQ ${stockItem.minimumOrderQuantity}`,
        stockItem.standardPackSize && `PACK ${stockItem.standardPackSize}`,
        stockItem.quantityMultiplier !== 1 &&
          `BUNDLE ${stockItem.quantityMultiplier}`,
      ]
        .filter((x) => x)
        .join(', ')) ||
    '';

  const activeStockLocationId =
    line.stockLocationId || defaultStockLocationId || undefined;
  const stockLevel =
    ((!!activeStockLocationId && !frozen_) || undefined) &&
    stockItem?.locationInfos.find(
      (x) => x.stockLocation.id === activeStockLocationId,
    )?.freeQuantity;

  const {
    removeLine,
    restoreLine,
    setDescription,
    setStockLocation,
    setQuantity,
    setUnitPrice,
  } = useMutations(orderId, line, orderStatus, orderType);

  return (
    <>
      <FirstRow
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={[hover ? 'hover' : '', line.suppressed ? 'suppressed' : '']
          .filter((x) => x)
          .join(' ')}
      >
        <TableCell size="small" align="left" style={{ paddingRight: 1 }}>
          <StockItemSelector
            line={line}
            orderId={orderId}
            debtorId={debtorId}
            orderStatus={orderStatus}
            orderType={orderType}
            orderDataFixed={orderDataFixed}
            autoFocused={totalLines === lineNum + 1 && addedLine}
          />
        </TableCell>
        <TableCell size="small" align="right">
          <div style={{ display: 'relative' }}>
            <EditableDisplay
              uppercase
              bad={!stockItem || !stockItem.isActive}
              editable={!frozen && !orderDataFixed}
              save={setDescription}
              value={line.description || line.stockItem?.description || ''}
              style={{ width: '20em' }}
              modifications={line.modifications.filter(
                (x) => x.field === 'description',
              )}
            />{' '}
            {line.description && (
              <IconButton
                tabIndex={-1}
                style={{ position: 'absolute', left: -45, top: -10 }}
                title="Use the stock item's default description"
                onClick={() => setDescription(null)}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        </TableCell>
        <TableCell size="small" align="right">
          <StockLocationPicker
            noHeading
            blankText="(use default)"
            stockLocationId={line.stockLocationId || null}
            setStockLocationId={setStockLocation}
            selectStyle={{ width: 120 }}
            inputStyle={{ paddingTop: 2, paddingBottom: 6 }}
            stockLevels={stockItem?.locationInfos.map((x) => ({
              level: x.freeQuantity,
              location: x.stockLocation.id,
            }))}
            fixedValue={
              frozen ? line.stockLocation?.name || '(use default)' : null
            }
            modifications={line.modifications.filter(
              (x) => x.field === 'stockLocationId',
            )}
          />
        </TableCell>

        <TableCell size="small" align="right">
          {orderType !== WipType.Manual && (
            <EditableDisplay
              bad={
                !line.quantityOrdered || line.quantityOrdered !== idealQuantity
              }
              editable={!frozen && !orderDataFixed}
              inputType="number"
              save={setQuantity}
              value={line.quantityOrdered?.toString() || ''}
              modifications={line.modifications.filter(
                (x) => x.field === 'quantityOrdered',
              )}
            />
          )}
          {orderType === WipType.Manual && (
            <ManualOrderLineQuantity
              orderStatus={orderStatus}
              line={line}
              currentQuantity={currentQuantity}
              setCurrentQuantity={setCurrentQuantity}
              setQuantity={setQuantity}
            />
          )}
        </TableCell>

        <TableCell size="small" align="right">
          {line.stockItem?.unitOfMeasure.name ?? 'N/A'}
        </TableCell>
        <TableCell size="small" align="right">
          {orderType !== WipType.Manual && (
            <EditableDisplay
              bad={badPrice}
              editable={!frozen && !orderDataFixed}
              inputType="number"
              save={setUnitPrice}
              value={line.unitPrice?.toString() || ''}
              modifications={line.modifications.filter(
                (x) => x.field === 'unitPrice',
              )}
            />
          )}
          {orderType === WipType.Manual && (
            <ManualOrderLineUnitPrice
              orderStatus={orderStatus}
              line={line}
              currentUnitPrice={currentUnitPrice}
              setCurrentUnitPrice={setCurrentUnitPrice}
              setUnitPrice={setUnitPrice}
              handleTabNewLine={handleTabNewLine}
            />
          )}
        </TableCell>
        <TableCell size="small" align="right">
          {isUnset(line.unitPrice) || isUnset(line.quantityOrdered)
            ? null
            : (line.unitPrice! * line.quantityOrdered!).toFixed(2)}
        </TableCell>
      </FirstRow>
      <SecondRow
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={[hover ? 'hover' : '', line.suppressed ? 'suppressed' : '']
          .filter((x) => x)
          .join(' ')}
      >
        <TableCell size="small" align="left">
          <div
            style={{ display: 'flex', alignItems: 'center', filter: 'unset' }}
          >
            {!frozen && (
              <SimpleActionButton
                style={{ padding: '0 8px', margin: '8px 4px 5px 0px' }}
                mutate={removeLine}
                color={'secondary'}
                // Ensures tab doesn't go to the DELETE button but the next row
                tabIndex={-1}
              >
                {line.added ? 'Delete' : 'Remove'}
              </SimpleActionButton>
            )}
            {!frozen_ && line.suppressed && (
              <SimpleActionButton
                style={{
                  filter: 'unset',
                  padding: '0 8px',
                  margin: '0 4px 0 -8px',
                }}
                mutate={restoreLine}
              >
                Restore
              </SimpleActionButton>
            )}
            <div>
              <Typography>{line.customerStockCode}</Typography>
              {stockItem && (
                <Typography
                  style={{
                    color: stockItem.isActive ? COLOR_INFO_GREEN : COLOR_BAD,
                  }}
                >
                  {stockItem.isActive ? stockItem.alert : 'INACTIVE STOCK CODE'}
                </Typography>
              )}
              {!line.stockCode && (
                <Typography style={{ color: COLOR_BAD }}>This field is required</Typography>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell size="small" align="right" style={{ width: 150 }}>
          <Typography
            className="customer-description"
            style={{ filter: line.suppressed ? 'opacity(40%)' : 'inherit' }}
          >
            {line.customerDescription}
          </Typography>
        </TableCell>
        <TableCell size="small" align="right">
          {stockItem && !frozen_ && (
            <Typography color="primary">
              {stockLevel === undefined
                ? 'NEED STOCK LOCATION'
                : typeof line.quantityOrdered === 'number' &&
                  stockLevel < line.quantityOrdered
                ? `STOCK: ${stockLevel}`
                : null}
            </Typography>
          )}
        </TableCell>
        <TableCell size="small" align="right">
          {stockItem && line.quantityOrdered !== idealQuantity && (
            <div>
              {frozen || orderDataFixed ? (
                <Typography color="info">{quantityTooltip}</Typography>
              ) : (
                <Tooltip title={quantityTooltip}>
                  <Button
                    color="primary"
                    style={{ padding: '0 8px', marginRight: -10 }}
                    onClick={() => {
                      const ideal = idealQuantity!.toString();
                      setCurrentQuantity(ideal);
                      setQuantity(ideal);
                    }}
                  >
                    Use {idealQuantity}
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
          {stockItem &&
            stockItem.standardPackSize &&
            stockItem.standardPackSize !== 1 &&
            line.quantityOrdered != null &&
            line.quantityOrdered === idealQuantity &&
            line.quantityOrdered % stockItem.standardPackSize !== 0 && (
              <Typography color="info">
                PACK {stockItem.standardPackSize}
              </Typography>
            )}
        </TableCell>
        <TableCell></TableCell>
        {badPrice ? (
          <>
            <TableCell size="small" align="right">
              {frozen || orderDataFixed ? (
                <Typography color="info">{financialUnitPrice}</Typography>
              ) : financialUnitPrice ? (
                <Button
                  color="primary"
                  style={{ padding: '0 8px', marginRight: -14 }}
                  onClick={() => {
                    const ideal = financialUnitPrice.toString();
                    setCurrentUnitPrice(ideal);
                    setUnitPrice(ideal);
                  }}
                >
                  Use {financialUnitPrice}
                </Button>
              ) : (
                <FormHelperText style={{color: COLOR_BAD, textAlign: 'right'}}>
                  {financialUnitPrice === 0
                    ? 'Should be free?!'
                    : 'Unknown Price!'}
                </FormHelperText>
              )}
            </TableCell>
            <TableCell size="small" align="right">
              <div>
                {isUnset(financialUnitPrice) || isUnset(line.quantityOrdered)
                  ? null
                  : (financialUnitPrice! * line.quantityOrdered!).toFixed(2)}
              </div>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" />
            <TableCell size="small" />
          </>
        )}
      </SecondRow>
    </>
  );
};

// NOTE: we don't consider pack size in this calculation. Perhaps there's a
// better estimate
function calculateIdealQuantity(
  current: number | null | undefined,
  moq: number | null | undefined,
  bundle: number,
) {
  if (!moq) moq = 1;
  if (!current || current === 0) current = moq;
  else if (moq && current < moq) current = moq;
  // Round up to next bundle
  const over = current % bundle;
  return current + (over ? bundle - over : 0);
}

function useMutations(
  orderId: string,
  line: Line,
  orderStatus: WipStatus,
  orderType: WipType,
) {
  const [removeLine] = useRemoveLineFromOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['Order'],
    variables: {
      input: {
        state: STATE_UNKNOWN,
        orderId: orderId,
        lineId: line.id,
      },
    },
  });

  const [restoreLine] = useRestoreLineOnOrderMutation({
    variables: {
      input: {
        state: STATE_UNKNOWN,
        orderId: orderId,
        lineId: line.id,
      },
    },
  });

  const setDescription = useProvideLineDescription(
    orderId,
    line,
    orderStatus,
    orderType,
  );

  const [provideStockLocation] = useProvideLineStockLocationMutation();
  const setStockLocation = (id: string | null) => {
    provideStockLocation({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId,
          lineId: line.id,
          stockLocationId: id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineStockLocation: {
          __typename: 'WipSalesOrderProvideLineStockLocationPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            stockLocationId: id,
            stockLocation: null,
            modifications:
              orderType !== WipType.Manual
                ? line.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'stockLocationId',
                      formerValue: line.stockLocationId,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const [provideQuantity] = useProvideLineQuantityMutation();
  const setQuantity = (value: string) => {
    let newValue: number | null = parseFloat(value);
    if (isNaN(newValue)) newValue = null;
    provideQuantity({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId,
          lineId: line.id,
          quantity: newValue,
        },
      },
      refetchQueries: ['Order'],
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineQuantity: {
          __typename: 'WipSalesOrderProvideLineQuantityPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            quantityOrdered: newValue,
            modifications:
              orderType !== WipType.Manual
                ? line.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'quantityOrdered',
                      formerValue: line.quantityOrdered,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const [provideUnitPrice] = useProvideLineUnitPriceMutation();
  const setUnitPrice = (value: string) => {
    let newValue: number | null = parseFloat(value);
    if (isNaN(newValue)) newValue = null;
    provideUnitPrice({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId,
          lineId: line.id,
          unitPrice: newValue,
        },
      },
      refetchQueries: ['Order'],
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineUnitPrice: {
          __typename: 'WipSalesOrderProvideLineUnitPricePayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            unitPrice: newValue,
            modifications:
              orderType !== WipType.Manual
                ? line.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'unitPrice',
                      formerValue: line.unitPrice,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  return {
    removeLine,
    restoreLine,
    setDescription,
    setStockLocation,
    setQuantity,
    setUnitPrice,
  };
}

export default OrderTableRow;

function useProvideLineDescription(
  orderId: string,
  line: Line,
  orderStatus: WipStatus,
  orderType: WipType,
) {
  const [provideDescription] = useProvideLineDescriptionMutation();
  const setDescription = (value: string | null) => {
    provideDescription({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId,
          lineId: line.id,
          description: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideLineDescription: {
          __typename: 'WipSalesOrderProvideLineDescriptionPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: orderId,
            state: STATE_UNKNOWN,
            status: orderStatus,
          },
          line: {
            __typename: 'WipSalesOrderLine',
            id: line.id,
            description: value,
            modifications:
              orderType !== WipType.Manual
                ? line.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'description',
                      formerValue: line.description,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };
  return setDescription;
}
