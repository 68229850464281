import React, { useState, useEffect, useCallback } from 'react';

import { Loading } from './Loading';
import { Error } from './Error';
import { isUnset, useStockItemPricing } from '../util';
import OrderTableRow from './OrderTableRow';
import { COLOR_INFO_GREEN } from '../constants';
import { SimpleActionButton } from './OrderActions';

import { NetworkStatus } from '@apollo/client';
import {
  OrderQuery,
  WipStatus,
  useAddLineToOrderMutation,
  useRerunRulesMutation,
  WipType,
  TaxType,
} from '../generated/graphql';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Swal from 'sweetalert2';
import { STATE_UNKNOWN } from '../services/apollo/setup';

export interface OrderTableProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

export const OrderTable: React.FC<OrderTableProps> = ({ order }) => {
  const frozen = [WipStatus.Rejected, WipStatus.Sent].includes(order.status);
  const [addedLine, setAddedLine] = useState(false);
  const orderTotal = order.lines
    .filter((line) => !line.suppressed)
    .map((line) =>
      isUnset(line.unitPrice) || isUnset(line.quantityOrdered)
        ? 0
        : line.unitPrice! * line.quantityOrdered!,
    )
    .reduce((a, b) => a + b, 0);
  const totalChanged = orderTotal !== order.originalOrderTotal;

  const { data, loading, error, networkStatus } = useStockItemPricing(
    order,
    true,
  );

  const [addLineMutate] = useAddLineToOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['Order'],
    variables: {
      input: {
        state: STATE_UNKNOWN,
        orderId: order.id,
        line: {
          stockCode: null,
          quantity: null,
          unitPrice: null,
          description: null,
          stockLocationId: null,
        },
      },
    },
  });

  const addLine = useCallback(() => {
    setAddedLine(true);
    return addLineMutate();
  }, [addLineMutate]);

  const [trigger] = useRerunRulesMutation({
    variables: {
      input: {
        state: STATE_UNKNOWN,
        orderId: order.id,
      },
    },
    refetchQueries: ['orderQuery'],
    awaitRefetchQueries: true,
  });

  const triggerRules = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This may erase manual stock location modifications',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e0e0e0',
      cancelButtonColor: '#f50057',
    });

    if (result.isConfirmed) {
      await trigger();
    }
  };

  // Needed because Apollo inconveniently sets data to undefined when its
  // variables change
  const [pricingData, setPricingData] = useState<typeof data>(undefined);
  useEffect(() => {
    if (data) {
      setPricingData(data);
    }
  }, [data, error]);

  if (loading && networkStatus !== NetworkStatus.setVariables) {
    return <Loading />;
  }

  if (error) return <Error style={{ padding: '2em' }} />;

  const pricing = pricingData?.prices || [];

  const suppressedLines = order.lines.filter((x) => x.suppressed);
  const addedLines = order.lines.filter((x) => x.added);

  const makeRowStat = (length: number, description: string) => (
    <>
      {length} row{length !== 1 && 's'} {description}
      <br />
    </>
  );

  return (
    <div style={{ marginBottom: '9em' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              size="small"
              align="left"
              style={{ minWidth: 140, maxWidth: 140 }}
            >
              Stock Code
            </TableCell>
            <TableCell size="small" align="right">
              Description
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ minWidth: 130, maxWidth: 130 }}
            >
              Stock Location
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ minWidth: 90, maxWidth: 90 }}
            >
              Quantity
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ minWidth: 90, maxWidth: 90 }}
            >
              Unit
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ minWidth: 110, maxWidth: 110 }}
            >
              Unit Price
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ minWidth: 95, maxWidth: 95, paddingLeft: 1 }}
            >
              Total Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.lines.map((line, i) => (
            <OrderTableRow
              key={line.id}
              state={order.state}
              orderId={order.id}
              debtorId={order.customerId}
              orderStatus={order.status}
              orderDataFixed={order.orderDataFixed}
              defaultStockLocationId={order.defaultStockLocationId}
              financialUnitPrice={pricing[i]?.price ?? null}
              /*Assuming they're automatic if type is null, backwards
              compatibility w / old automated orders that never had this field*/
              orderType={order.type ?? WipType.Automated}
              line={line}
              frozen={frozen}
              addLine={addLine}
              addedLine={addedLine}
              lineNum={i}
              totalLines={addedLines.length}
            />
          ))}
          <TableRow>
            <TableCell
              size="small"
              align="left"
              style={{ borderBottom: 0, verticalAlign: 'top' }}
            >
              {makeRowStat(order.lines.length, 'in total')}
              {suppressedLines.length > 0 &&
                makeRowStat(
                  order.lines.length - suppressedLines.length,
                  'active',
                )}
              {addedLines.length > 0 &&
                order.type === WipType.Automated &&
                makeRowStat(
                  order.lines.length - addedLines.length,
                  'on original order',
                )}
              {suppressedLines.length > 0 &&
                makeRowStat(suppressedLines.length, 'removed')}
              {addedLines.length > 0 &&
                order.type === WipType.Automated &&
                makeRowStat(addedLines.length, 'added')}
            </TableCell>
            <TableCell
              size="small"
              align="right"
              colSpan={2}
              style={{ borderBottom: 0 }}
            >
              <SimpleActionButton
                tabIndex={-1}
                disabled={
                  order.status === WipStatus.Rejected ||
                  order.status === WipStatus.Sent
                }
                mutate={triggerRules}
              >
                Optimise Stock Locations
              </SimpleActionButton>
            </TableCell>
            <TableCell
              size="small"
              align="right"
              colSpan={2}
              style={{ borderBottom: 0, verticalAlign: 'top' }}
            >
              Total Price (GST{' '}
              {order.taxType === TaxType.GstInclusive ? 'Incl' : 'Excl'}.)
              {totalChanged && (
                <>
                  <br />
                  <span style={{ color: COLOR_INFO_GREEN }}>
                    Before Modifications
                  </span>
                </>
              )}
            </TableCell>
            <TableCell
              size="small"
              align="right"
              style={{ borderBottom: 0, verticalAlign: 'top' }}
            >
              ${orderTotal.toFixed(2)}
              {totalChanged && (
                <>
                  <br />
                  <span style={{ color: COLOR_INFO_GREEN }}>
                    ${order.originalOrderTotal.toFixed(2)}
                  </span>
                </>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!frozen && !order.orderDataFixed && (
        <SimpleActionButton
          mutate={addLine}
          style={{ float: 'left', margin: '2em' }}
          tabIndex={-1}
        >
          Add Row
        </SimpleActionButton>
      )}
    </div>
  );
};
