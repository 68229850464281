import React from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import { WipStatus } from '../../generated/graphql';
import { TextField } from '@mui/material';
import { Line } from '../OrderTableRow';

export interface ManualOrderLineUnitPriceProps {
  orderStatus: WipStatus;
  line: Line;
  currentUnitPrice: string | null;
  setCurrentUnitPrice: (value: string) => void;
  setUnitPrice: (value: string) => void;
  handleTabNewLine: any;
}

const ManualOrderLineUnitPrice: React.FC<ManualOrderLineUnitPriceProps> = ({
  orderStatus,
  line,
  currentUnitPrice,
  setCurrentUnitPrice,
  setUnitPrice,
  handleTabNewLine,
}) => {
  const doMutation = useDebouncedCallback((value: string) => {
    setUnitPrice(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrentUnitPrice(val);
    doMutation(val);
  };

  return (
    <div>
      <TextField
        style={{ width: 50 }}
        disabled={
          orderStatus === WipStatus.Rejected || orderStatus === WipStatus.Sent
        }
        value={currentUnitPrice ?? line.unitPrice}
        onChange={onChange}
        onKeyDown={(e) => handleTabNewLine(e)}
        variant="standard"
      />
    </div>
  );
};

export default ManualOrderLineUnitPrice;
