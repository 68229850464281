import React, { useState } from 'react';
import DocumentDisplay, { OriginalSource } from './DocumentDisplay';
import { Button } from '@mui/material';

export interface DocumentsProps {
  documents: OriginalSource[];
}

const Documents: React.FC<DocumentsProps> = ({ documents }) => {
  const [showAll, setShowAll] = useState(false);
  const filtered = documents.filter((x) => x.show);
  const toShow = showAll ? documents : filtered;
  return (
    <div style={{ paddingBottom: '2em' }}>
      {documents.length !== filtered.length && (
        <Button
          style={{ marginBottom: '2em' }}
          onClick={() => setShowAll((x) => !x)}
        >
          {showAll ? 'Hide Extra' : 'Show All Documents'}
        </Button>
      )}
      {toShow.map((document) => (
        <div key={document.id} style={{ width: '100%', marginBottom: '4em' }}>
          <DocumentDisplay originalSource={document} />
        </div>
      ))}
    </div>
  );
};

export default Documents;
