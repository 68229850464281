import React, { useState } from 'react';

import { Error } from '../Error';
import { ModificationDisplay } from '../ModificationDisplay';
import { InputUnderline } from '../InputUnderline';

import {
  useSalesBranchesQuery,
  FieldModification,
  useProvideSalesBranchMutation,
  OrderQuery,
  WipType,
} from '../../generated/graphql';
import { Typography, Select, MenuItem, LinearProgress, FormHelperText } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';
import { COLOR_BAD } from "../../constants";

export interface SalesBranchPickerProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
  fixedValue: string | null;
  modifications: FieldModification[];
}

const SalesBranchPicker: React.FC<SalesBranchPickerProps> = ({
  order,
  fixedValue,
  modifications,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { loading, error, data } = useSalesBranchesQuery({
    fetchPolicy: 'cache-first',
  });

  const [provideSalesBranch] = useProvideSalesBranchMutation();
  const setSalesBranchId = (id: string) => {
    provideSalesBranch({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          salesBranchId: id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideSalesBranch: {
          __typename: 'WipSalesOrderProvideSalesBranchPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'salesBranchId',
                      formerValue: order.salesBranchId,
                      date: new Date(),
                    },
                  ])
                : [],
            salesBranchId: id,
            salesBranch: {
              __typename: 'SalesBranch',
              name: '',
            },
          },
        },
      },
    });
  };

  if (loading) return <LinearProgress />;
  if (error || !data) return <Error />;

  const branches = data.salesBranches;
  return (
    <ModificationDisplay
      modifications={modifications}
      processValue={(id: string | null) =>
        id === null ? null : branches.find((x) => x.id === id)?.name ?? '??'
      }
      tooltipOpen={tooltipOpen}
    >
      <InputUnderline enable={modifications.length > 0}>
        <Typography variant="h6">Sales Branch</Typography>
        {fixedValue ? (
          <Typography>{fixedValue}</Typography>
        ) : (
          <Select
            style={{ width: 150 }}
            value={order.salesBranchId || ''}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            onMouseDown={() => setTooltipOpen(false)}
            onChange={(e) => setSalesBranchId(e.target.value as string)}
            error={!order.salesBranch}
            variant="standard"
            required
          >
            {branches.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        )}
          {!order.salesBranchId && (
              <FormHelperText style={{ color: COLOR_BAD }}>This field is required</FormHelperText>
          )}
      </InputUnderline>
    </ModificationDisplay>
  );
};

export default SalesBranchPicker;
