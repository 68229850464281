import React, { useEffect, useState } from 'react';

import { Loading } from './Loading';

import { FetchResult } from '@apollo/client';
import {
  WipStatus,
  WipType,
  useDeleteOrderMutation,
  useRejectOrderMutation,
  useSendOrderMutation,
  useProvideBackOrderMutation,
  OrderQuery
} from '../generated/graphql';
import { Button, Typography, ButtonProps, FormHelperText } from '@mui/material';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { STATE_UNKNOWN } from '../services/apollo/setup';
import { COLOR_BAD } from "../constants";
import Checkbox from '@mui/material/Checkbox';

export interface OrderActionsProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

interface ActionButtonProps {
  mutate: () => Promise<FetchResult>;
}

const ActionButtonStyle = styled(Button)`
  padding: 0.5em;
  margin: 1em 0.5em;
  box-shadow: none;
  position: relative;
  color: white;

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SimpleActionButton: React.FC<
  { mutate: () => Promise<any> } & ButtonProps
> = ({children, mutate, disabled, ...props}) => {
  const [ loading, setLoading ] = useState(false);
  const onClick = () => {
    setLoading(true);
    mutate().finally(() => setLoading(false));
  };
  return (
    <ActionButtonStyle
      {...props}
      onClick={onClick}
      disabled={loading || disabled}
      variant="contained"
    >
      {children}
      {loading && <Loading className="loading"/>}
    </ActionButtonStyle>
  );
};

export const ActionButton: React.FC<
  ActionButtonProps & ButtonProps & { errorMessage: string }
> = ({mutate, errorMessage, ...props}) => {
  const onClick = () => {
    return mutate()
      .then((res) => {
        if (res.errors || !res.data || Object.values(res.data)[0]?.error) {
          return;
        }

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Done',
          timer: 500,
          showConfirmButton: false,
        });
      })
      .catch((e) => {
        if (e === null) return;
        Sentry.captureMessage(errorMessage, Sentry.Severity.Error);
        Swal.fire({
          icon: 'error',
          title: errorMessage,
        });
      });
  };
  return <SimpleActionButton {...props} variant="contained" mutate={onClick}/>;
};

const OrderActions: React.FC<OrderActionsProps> = ({order}) => {
  const {
    id,
    resultingOrder,
    processedBy,
    status,
    type,
    needsBackorder
  } = order;
  const [ deleteOrder ] = useDeleteOrderMutation({
    variables: {
      input: {
        state: STATE_UNKNOWN,
        id: id,
      },
    },
  });
  const [ reject ] = useRejectOrderMutation({
    variables: {
      input: {
        state: STATE_UNKNOWN,
        id: id,
      },
    },
  });

  const [ send ] = useSendOrderMutation({
    variables: {
      input: {
        state: STATE_UNKNOWN,
        id: id,
        // Custom warning logic to tell users when forcing is required
        tryForce: true,
      },
    },
  });

  const [ provideWillBackOrder ] = useProvideBackOrderMutation();

  const setWillBackorder = async (value: boolean) => {
    await provideWillBackOrder({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: id,
          backOrder: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideBackOrder: {
          __typename: 'WipSalesOrderProvideBackOrderPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                  {
                    __typename: 'FieldModification',
                    field: 'backOrder',
                    formerValue: order.backOrder,
                    date: new Date(),
                  },
                ])
                : [],
            backOrder: value,
          },
        },
      },
    });
  };

  const [ checked, setChecked ] = useState(false);

  const checkedHandler = async () => {
    const newValue = !checked;
    setChecked(newValue);
    await setWillBackorder(newValue);
  };

  useEffect(() => {
    setChecked(order.backOrder!);
  }, [order.backOrder]);


  const gatedSend = async () => {
    if (status !== WipStatus.Sendable) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This order is invalid',
        icon: 'warning',
        focusCancel: true,
        showCancelButton: true,
        showConfirmButton: true,
      });

      if (!result.isConfirmed) {
        // User cancelled - tell button component that it "failed", but ok.
        // eslint-disable-next-line no-throw-literal
        throw null;
      }
    }

    if (needsBackorder && !order.backOrder) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This order needs to be back ordered. Do you want to continue without back ordering?',
        icon: 'warning', focusCancel: true,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (!result.isConfirmed) {
        // eslint-disable-next-line no-throw-literal
        throw null;
      }
    }

    return await send();
  };

  const wrap = (x: any) => (
    <div
      style={{
        display: 'flex',
        alignSelf: 'left',
        justifySelf: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      {x}
    </div>
  );

  if (status === WipStatus.Deleted) {
    return wrap(
      <div style={{ textAlign: 'right' }}>
        <Typography color="error">Deleted</Typography>
        {processedBy && (
          <Typography color="info">
            {`Deleted by ${processedBy.givenName} ${processedBy.familyName}`}
          </Typography>
        )}
      </div>,
    );
  }

  if (status === WipStatus.Rejected) {
    return wrap(
      <div style={{ textAlign: 'right' }}>
        <Typography color="error">Rejected</Typography>
        {processedBy && (
          <Typography color="info">
            {`Rejected by ${processedBy.givenName} ${processedBy.familyName}`}
          </Typography>
        )}
      </div>,
    );
  }

  if (status === WipStatus.Sent) {
    return wrap(
      <div style={{ textAlign: 'right' }}>
        <Typography style={{ color: 'rgb(76, 175, 80)' }}>
          SO# {resultingOrder?.id}
        </Typography>
        {processedBy && (
          <Typography style={{ color: 'rgb(74, 175, 80)' }}>
            {`Confirmed by ${processedBy.givenName} ${processedBy.familyName}`}
          </Typography>
        )}
        <Typography style={{ color: 'rgb(76, 175, 80)' }}>
          {resultingOrder?.internalReference &&
            `OUR REF: ${resultingOrder.internalReference}`}
        </Typography>
      </div>,
    );
  }

  return wrap(
    <>
      {needsBackorder && (
        <>
          <FormHelperText
            style={{ color: COLOR_BAD, alignSelf: 'center' }}
          >
            Not enough stock at a single stock location to fulfill this order in full
          </FormHelperText>
        </>
      )}

      <div
        style={{
          width: '180px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          float: 'left',
        }}
      >
        <Checkbox
          checked={checked}
          color="primary"
          onChange={() => {
            checkedHandler();
          }}
          size="medium"
        />
        <Typography variant="h6">Backorder</Typography>
      </div>

      <ActionButton
        color="error"
        mutate={type === WipType.Manual ? deleteOrder : reject}
        errorMessage="Well, uhh... that didn't work. Please contact the development team."
        tabIndex={-1}
      >
        {type === WipType.Manual ? 'Delete' : 'Reject'}
      </ActionButton>
      <ActionButton
        color={status === WipStatus.Invalid ? 'warning' : 'primary'}
        mutate={gatedSend}
        tabIndex={-1}
        errorMessage={
          status === WipStatus.Invalid
            ? "<span>That didn't work. Maybe the order really is <em>too</em> broken to submit. Please contact the development team if you believe otherwise.</span>"
            : "Well, uhh... that didn't work. Please contact the development team."
        }
      >
        Confirm
      </ActionButton>
    </>,
  );
};

export default OrderActions;
