import React from 'react';

import { ModificationDisplay } from './ModificationDisplay';
import { COLOR_BAD, COLOR_INFO_GREEN } from '../constants';

import { FieldModification } from '../generated/graphql';
import { TextField, Button, Typography } from '@mui/material';
import styled from 'styled-components';

interface EditableEntryStyleProps {
  modified: boolean;
  empty: boolean;
  editable: boolean;
  bad: boolean;
}

const EditableEntryStyle = styled.span<EditableEntryStyleProps>`
  ${(props) => props.bad && `color: ${COLOR_BAD};`}
  ${(props) =>
    props.modified &&
    `
    border-bottom: 4px solid ${COLOR_INFO_GREEN};
    position: relative;
    display: inline-block;
  `};

  ${(props) =>
    props.empty &&
    `
    & .empty {
      color: ${COLOR_BAD};
      font-weight: 600;
    }
  `};

  ${(props) => props.editable && `cursor: pointer;`}
`;

export interface EditableDisplayProps {
  editable: boolean;
  bad?: boolean;
  uppercase?: boolean;
  multiline?: boolean;
  save: (value: string) => void;
  value: string;
  inputType?: string;
  modifications: FieldModification[];
  style?: React.CSSProperties;
}

interface EditableDisplayState {
  editing: boolean;
  editValue: string;
}

export class EditableDisplay extends React.Component<
  EditableDisplayProps,
  EditableDisplayState
> {
  constructor(props: EditableDisplayProps) {
    super(props);
    this.state = {
      editing: false,
      editValue: '',
    };
  }

  wantsToEdit = () => {
    if (!this.props.editable) return;
    this.setState({
      editing: true,
      editValue: this.props.value,
    });
  };

  saveValue = () => {
    this.setState({ editing: false });
    this.props.save(this.state.editValue);
  };

  cancelSet = () => {
    this.setState({ editing: false, editValue: this.props.value });
  };

  editInputValue = (event: any) => {
    let newVal = event.target.value as string;
    if (this.props.uppercase) newVal = newVal.toUpperCase();
    this.setState({
      editValue: newVal,
    });
  };

  handleInputKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.saveValue();
    }
  };

  render() {
    if (this.state.editing) {
      return (
        <div>
          <TextField
            multiline={this.props.multiline}
            style={{ width: '100%' }}
            inputProps={{ style: this.props.style }}
            type={this.props.inputType}
            onKeyPress={this.handleInputKeyPress}
            onChange={this.editInputValue}
            value={this.state.editValue}
            variant="standard"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 3,
            }}
          >
            <Button onClick={this.cancelSet} style={{ marginLeft: 3 }}>
              Cancel
            </Button>
            <Button onClick={this.saveValue}>OK</Button>
          </div>
        </div>
      );
    }

    const modified = this.props.modifications.length !== 0;
    const data = this.props.value.toString().trim();
    const content = (
      <EditableEntryStyle
        modified={modified}
        bad={!!this.props.bad}
        editable={this.props.editable}
        onClick={this.wantsToEdit}
        empty={data === ''}
      >
        {data === '' ? (
          <Typography className="empty" style={this.props.style}>
            EMPTY
          </Typography>
        ) : (
          <Typography
            style={{
              whiteSpace: this.props.multiline ? 'pre-line' : undefined,
              ...(this.props.style ?? {}),
            }}
          >
            {this.props.value}
          </Typography>
        )}
      </EditableEntryStyle>
    );

    if (modified) {
      return (
        <ModificationDisplay modifications={this.props.modifications}>
          {content}
        </ModificationDisplay>
      );
    }

    return content;
  }
}
