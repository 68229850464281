import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import {
  useProvideInstructionsMutation,
  OrderQuery,
  WipStatus,
} from '../../generated/graphql';
import { Typography, FormControl, TextField } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface InstructionsProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const Instructions: React.FC<InstructionsProps> = ({ order }) => {
  const [current, setCurrent] = useState<string | null>(null);

  const [provideInstructions] = useProvideInstructionsMutation();
  const setInstructions = (value: string) => {
    provideInstructions({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          instructions: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideInstructions: {
          __typename: 'WipSalesOrderProvideInstructionsPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            instructions: value,
          },
        },
      },
    });
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setInstructions(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.toUpperCase();
    setCurrent(val);
    doMutation(val);
  };

  return (
    <div>
      <Typography variant="h6">Freight Instructions</Typography>
      <FormControl style={{ width: 250 }} variant="standard">
        <TextField
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          multiline={true}
          value={current ?? (order.instructions || '')}
          onChange={onChange}
          variant="standard"
        />
      </FormControl>
    </div>
  );
};

export default Instructions;
