import React from 'react';
import { VersionNumber } from './VersionNumber';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { getAuthProvider, authEnabled } from '../services/auth';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { env } from './../runtime-environment'

interface RootContainerProps {
  env: string;
  children: React.ReactNode;
}

const RootContainer: React.FC<RootContainerProps> = ({ env, children }) => {
  return (
    <div style={env === 'staging' ? { border: '5px solid red' } : {}}>
      {children}
    </div>
  )
}

export function AppFrame({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}) {
  const root = useLocation().pathname === '/';

  return (
    <>
      <AppBar
        position="fixed"
        style={{ backgroundColor: '#375954', zIndex: 100 }}
      >
        <RootContainer env={env.profile}>
          <Toolbar>
            {!drawerOpen && root && (
              <IconButton
                edge="start"
                onClick={() => setDrawerOpen(true)}
                color={'default'}
                style={{ color: '#fff' }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap style={{ flex: '1 0' }}>
              SalesHandler
            </Typography>

            <Button
              component={Link}
              to={root ? '/debtors' : '/'}
              style={{ color: '#fff', marginRight: '2em', width: '10em' }}
            >
              {root ? 'Recent Orders' : 'Home'}
            </Button>

            <VersionNumber />
            <LogoutButton />
          </Toolbar>
        </RootContainer>
      </AppBar>
      <Outlet />
    </>
  );
}

function LogoutButton() {
  const logout = () => {

    if (authEnabled) {
      getAuthProvider().logoutRedirect();
    }
  };

  return (
    <Button
      style={{ marginLeft: '2em' }}
      color="error"
      onClick={logout}
      variant={'contained'}
    >
      Logout
    </Button>
  );
}
