import React from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import { WipStatus } from '../../generated/graphql';
import { TextField } from '@mui/material';
import { Line } from '../OrderTableRow';

export interface ManualOrderLineQuantityProps {
  orderStatus: WipStatus;
  line: Line;
  currentQuantity: string | null;
  setQuantity: (value: string) => void;
  setCurrentQuantity: (value: string) => void;
}

const ManualOrderLineQuantity: React.FC<ManualOrderLineQuantityProps> = ({
  currentQuantity,
  orderStatus,
  line,
  setQuantity,
  setCurrentQuantity,
}) => {
  const doMutation = useDebouncedCallback((value: string) => {
    setQuantity(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrentQuantity(val);
    doMutation(val);
  };

  return (
    <div>
      <TextField
        style={{ width: 50 }}
        disabled={
          orderStatus === WipStatus.Rejected || orderStatus === WipStatus.Sent
        }
        value={currentQuantity ?? line.quantityOrdered}
        onChange={onChange}
        variant="standard"
      />
    </div>
  );
};

export default ManualOrderLineQuantity;
