import { env } from '../runtime-environment';
import * as msal from '@azure/msal-browser';

const { clientId, tenantId, apiIdentifier } = env.azureAd;

const authority = `https://login.microsoftonline.com/${tenantId}`;

const config: msal.Configuration = {
  auth: {
    authority,
    clientId,
    // The configured reply URL
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const authEnabled = !!(clientId && tenantId && apiIdentifier);

let provider: msal.PublicClientApplication | null = null;
export const getAuthProvider = (): msal.PublicClientApplication => {
  if (provider) return provider;

  provider = new msal.PublicClientApplication(config);
  return provider;
};

export const authenticationRequest: msal.RedirectRequest = {
  scopes: [apiIdentifier],
};

export const getAccessToken = () => {
  const provider = getAuthProvider();
  return provider
    .acquireTokenSilent({
      scopes: [apiIdentifier],
      account: provider.getAllAccounts()[0],
    })
    .catch(async (error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return await provider.acquireTokenPopup({
          scopes: [apiIdentifier],
          account: provider.getAllAccounts()[0],
        });
      }

      throw error;
    });
};
