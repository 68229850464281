import React, { useEffect, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { InputUnderline } from '../InputUnderline';
import { DEBOUNCE_MS } from '../../util';

import {
  FieldModification,
  useProvideDeliveryAddressMutation,
  OrderQuery,
  WipType,
  WipStatus,
} from '../../generated/graphql';
import { Typography, FormControl, TextField } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface AddressDisplayProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
  modifications: FieldModification[];
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({
  order,
  modifications,
}) => {
  const [current, setCurrent] = useState<string | null>(null);
  const [selection, setSelection] = useState<[number, number] | null>(null);
  const textField = useRef<HTMLTextAreaElement>();

  const [provideDeliveryAddress] = useProvideDeliveryAddressMutation();
  const setDeliveryAddress = (value: string[]) => {
    provideDeliveryAddress({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          deliveryAddress: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideDeliveryAddress: {
          __typename: 'WipSalesOrderProvideDeliveryAddressPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            deliveryAddress: value,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'deliveryAddress',
                      formerValue: order.deliveryAddress,
                      date: new Date(),
                    },
                  ])
                : [],
          },
        },
      },
    });
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setDeliveryAddress(
      value
        .split('\n')
        .map((x) => x.trim())
        .filter((x) => x.length !== 0),
    );
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { selectionStart, selectionEnd } = e.target;
    setSelection([selectionStart, selectionEnd]);

    const val = e.target.value.toUpperCase();
    setCurrent(val);
    doMutation(val);
  };

  useEffect(() => {
    if (!selection) return;
    textField.current?.setSelectionRange(selection[0], selection[1]);
  }, [selection]);

  return (
    <InputUnderline enable={modifications.length > 0}>
      <Typography variant="h6">Address</Typography>
      <FormControl style={{ width: 250 }} variant="standard">
        <TextField
          inputRef={textField}
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          multiline={true}
          value={
            current ||
            order.deliveryAddress?.join('\n') ||
            order.customer?.deliveryAddress?.join('\n') ||
            ''
          }
          onChange={onChange}
          variant="standard"
        />
      </FormControl>
    </InputUnderline>
  );
};

export default AddressDisplay;
