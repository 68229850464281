import React from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import { DisplayStyle } from './DisplayStyle';
import { Loading } from '../Loading';
import { Error } from '../Error';

const TextStyle = styled.div`
  height: 100%;
  padding: 2em;
  overflow: auto;

  code {
    display: block;
    white-space: pre-wrap;
  }
`;

const TextDisplay: React.FC<{ url: string }> = ({ url }) => {
  const [{ data, loading, error }] = useAxios(url);
  return (
    <DisplayStyle>
      {loading && (
        <div className="center-message">
          <Loading />
        </div>
      )}
      {error && (
        <div className="center-message">
          <Error />
        </div>
      )}
      {data && (
        <TextStyle>
          <code>
            {typeof data !== 'string'
              ? JSON.stringify(data, null, 2)
              : data.toString()}
          </code>
        </TextStyle>
      )}
    </DisplayStyle>
  );
};

export default TextDisplay;
