import { COLOR_INFO_GREEN } from '../constants';
import styled from 'styled-components';

export const InputUnderline = styled.div<{
  enable: boolean;
}>`
  .MuiInput-root::before {
    ${({ enable }) =>
      enable &&
      `
      border-bottom: 4px solid ${COLOR_INFO_GREEN};
    `}
  }
`;
