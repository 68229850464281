import React from 'react';
import { DisplayStyle } from './DisplayStyle';

const HtmlDisplay: React.FC<{ url: string }> = ({ url }) => (
  <DisplayStyle
    as="iframe"
    src={url}
    sandbox="allow-same-origin"
    title="email-display"
  >
    <p>Unable to view the document.</p>
  </DisplayStyle>
);

export default HtmlDisplay;
