import React, { useState } from 'react';
import { useDebounce } from '@react-hook/debounce';

import { DRAWER_WIDTH, POLL_INTERVAL_MS, DRAWER_PAGE_SIZE } from '../constants';
import { Error } from './Error';

import {
  OrderListQuery,
  useOrderListQuery,
  useSalesBranchesQuery,
  WipStatus,
  WipType,
} from '../generated/graphql';
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Select,
  Typography,
  MenuItem,
  Tooltip,
  TextField,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Done as DoneIcon,
  NotInterested as NotInterestedIcon,
  Warning as WarningIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  Create as CreateIcon,
} from '@mui/icons-material';
import dateFormat from 'dateformat';
import styled from 'styled-components';

interface OrderSideDrawerProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  selectedOrderId: string | null;
  selectOrder: (id: string | null) => void;
  handleNewSalesOrder: () => void;
}

const SEARCH_DEBOUNCE = 250;

export const OrderSideDrawer: React.FC<OrderSideDrawerProps> = ({
  open,
  setOpen,
  selectedOrderId,
  selectOrder,
  handleNewSalesOrder,
}) => {
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useDebounce('', SEARCH_DEBOUNCE);
  const [salesBranchId, setSalesBranch] = useState<string | null>(null);
  const [viewPending, setViewPending] = useState(true);
  const [page, setPage] = useState(0);

  const actualSearchQuery = searchQuery.trim();
  const { loading, error, data } = useOrderListQuery({
    pollInterval: POLL_INTERVAL_MS,
    variables: {
      newest: !viewPending,
      searchQuery: searching ? actualSearchQuery : null,
      salesBranchId,
      skip: searching ? null : page * DRAWER_PAGE_SIZE,
      take: viewPending && !searching ? null : DRAWER_PAGE_SIZE,
      statuses: searching
        ? null
        : viewPending
        ? [WipStatus.Invalid, WipStatus.Sendable]
        : [WipStatus.Rejected, WipStatus.Sent],
    },
  });

  let contents = null;
  if (loading) contents = <LinearProgress />;
  else if (error || !data) contents = <Error style={{ padding: '2em' }} />;
  else {
    contents = (
      <DrawerList
        orderList={data.wipSalesOrders}
        selectOrder={selectOrder}
        selectedOrderId={selectedOrderId}
      />
    );
  }

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: 'paper' }}
    >
      <DrawerHeader>
        {!searching && (
          <Button
            onClick={() => {
              if (!viewPending) setPage(0);
              setViewPending((x) => !x);
            }}
          >
            {viewPending ? 'Pending' : 'Processed'}
          </Button>
        )}
        {searching && (
          <TextField
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginRight: '0.5em' }}
            placeholder="Search..."
            variant="standard"
          />
        )}
        <SalesBranchFilter salesBranchId={salesBranchId} set={setSalesBranch} />
        <div>
          <IconButton
            style={{ padding: '12px 6px' }}
            onClick={() => {
              setSearching((s) => !s);
              setSearchQuery('');
            }}
          >
            {searching ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
          <IconButton
            onClick={() => {
              handleNewSalesOrder();
            }}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            style={{ padding: '12px 6px', marginRight: 6 }}
            onClick={() => setOpen(false)}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </DrawerHeader>
      {!viewPending && !searching && (
        <>
          <Divider />
          <DrawerArrows>
            <IconButton
              disabled={page === 0}
              onClick={() => setPage((p) => p - 1)}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography>
              {page * DRAWER_PAGE_SIZE + 1} - {(page + 1) * DRAWER_PAGE_SIZE}
            </Typography>
            <IconButton onClick={() => setPage((p) => p + 1)}>
              <ChevronRightIcon />
            </IconButton>
          </DrawerArrows>
        </>
      )}
      <Divider />
      {contents}
    </StyledDrawer>
  );
};

const SalesBranchFilter: React.FC<{
  salesBranchId: string | null;
  set: (value: string | null) => void;
}> = ({ salesBranchId, set }) => {
  const { loading, error, data } = useSalesBranchesQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <LinearProgress />;
  if (error || !data) return <Error />;

  return (
    <Select
      style={{ width: 130 }}
      value={salesBranchId || -1}
      onChange={(e) => {
        const { value } = e.target;
        if (value === -1) set(null);
        else set(value as string);
      }}
      variant="standard"
    >
      <MenuItem value={-1}>All Branches</MenuItem>
      {data.salesBranches.map((b) => (
        <MenuItem key={b.id} value={b.id}>
          {b.name}
        </MenuItem>
      ))}
    </Select>
  );
};

interface DrawerListProps {
  orderList: Exclude<OrderListQuery['wipSalesOrders'], null | undefined>;
  selectedOrderId: string | null;
  selectOrder: (id: string | null) => void;
}

const TOOLTIP_MAP: Record<WipStatus, string> = {
  INVALID: 'Invalid',
  REJECTED: 'Rejected',
  SENDABLE: 'Can Post',
  SENT: 'Posted',
  DELETED: 'Deleted',
};

const DrawerList: React.FC<DrawerListProps> = ({
  selectedOrderId,
  selectOrder,
  orderList,
}) => (
  <List className="list">
    {orderList.length === 0 && (
      <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography>
          Nothing to see here...{' '}
          <span role="img" aria-label="nice">
            👌
          </span>
        </Typography>
      </ListItem>
    )}
    {orderList.map((order) => (
      <ListItem
        button
        key={order.id}
        onClick={() =>
          selectOrder(order.id === selectedOrderId ? null : order.id)
        }
        className={`item ${order.status} ${
          order.id === selectedOrderId ? 'selected' : ''
        }`}
      >
        <Tooltip title={TOOLTIP_MAP[order.status]}>
          <div className="icon">
            {order.status === WipStatus.Sendable && <DoneIcon />}
            {order.status === WipStatus.Invalid && <WarningIcon />}
            {order.status === WipStatus.Rejected && <NotInterestedIcon />}

            {order.resultingOrder && (
              <Typography>{order.resultingOrder.id}</Typography>
            )}
          </div>
        </Tooltip>

        <div className="text">
          {!order.customer &&
            !order.customerReference &&
            order.type !== WipType.Manual && <Typography>???</Typography>}
          {order.type === WipType.Manual && <div>Manual Order</div>}
          {order.customer && <Typography>{order.customer.name}</Typography>}
          {order.customerReference && (
            <Typography>{order.customerReference}</Typography>
          )}
        </div>

        <Typography className="date">
          {formatDate(
            [WipStatus.Sent, WipStatus.Rejected].includes(order.status)
              ? order.dateProcessed || null
              : order.dateSubmitted,
          )}
        </Typography>
      </ListItem>
    ))}
  </List>
);

function formatDate(_date: string | null) {
  if (!_date) return '?';

  const date = new Date(_date);
  const now = new Date();

  const sameDay = date.getDate() === now.getDate();
  const sameMonth = date.getMonth() === now.getMonth();
  const sameYear = date.getFullYear() === now.getFullYear();
  if (sameDay && sameMonth && sameYear) {
    return dateFormat(date, 'HH:MM:ss');
  }

  return dateFormat(date, 'yyyy-mm-dd');
}

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 1.5em;
`;

const DrawerArrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
`;

const StyledDrawer = styled(Drawer)`
  .paper {
    z-index: 30;
    position: fixed;
    top: 64px;
    height: calc(100vh - 64px);

    width: 100%;
    @media (min-width: 800px) {
      width: ${DRAWER_WIDTH}px;
    }
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
  }

  .item {
    display: flex;
    align-items: center;
    height: 72px;
  }

  .icon {
    display: flex;
    place-items: center;
  }

  .text {
    flex: 1 1;
    padding: 0 1em 0 1.5em;
    overflow: hidden;

    p {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .date {
    flex: 0 0 85px;
    font-size: 16px;
    text-align: end;
  }

  * {
    white-space: nowrap;
  }

  .REJECTED {
    color: red;
  }

  .selected {
    background-color: #eee;
  }
`;
