import React from 'react';
import { FieldModification } from '../generated/graphql';
import { Tooltip } from '@mui/material';
import dateFormat from 'dateformat';

export interface ModificationDisplayProps {
  modifications: FieldModification[];
  /** How to convert the value given to the component into what to display in
   * the tooltip */
  processValue?: (v: any) => any;
  tooltipOpen?: boolean;
  children: React.ReactElement;
}

// By default, display an array as a list of lines with extra newlines on each
// end
const defaultProcessValue = (x: any) =>
  Array.isArray(x) ? '\n' + x.join('\n') + '\n' : x;

export const ModificationDisplay: React.FC<ModificationDisplayProps> = ({
  modifications,
  processValue = defaultProcessValue,
  tooltipOpen,
  children,
}) => {
  const ordered = [...modifications].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  if (modifications.length === 0) return children;
  if (ordered.length > 8) {
    ordered.splice(4, ordered.length - 8);
  }

  return (
    <Tooltip
      tabIndex={-1}
      open={tooltipOpen}
      title={
        <div>
          {ordered.map((m, i) => {
            const value = processValue(m.formerValue) || 'EMPTY';
            return (
              <React.Fragment key={m.date}>
                <p
                  style={
                    typeof value === 'string' && value.includes('\n')
                      ? { whiteSpace: 'pre-line' }
                      : undefined
                  }
                >
                  {dateFormat(new Date(m.date), 'yyyy-mm-dd')}: was {value}
                  {i === 0 && ' (initial)'}
                </p>
                {i === 3 && modifications.length > 8 && <p>...</p>}
              </React.Fragment>
            );
          })}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
