import React from 'react';

import {
  OrderQuery,
  useProvideOnHoldMutation,
  WipStatus,
  WipType,
} from '../../generated/graphql';
import { Checkbox, Typography } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface OnHoldCheckboxProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const OnHoldCheckbox: React.FC<OnHoldCheckboxProps> = ({ order }) => {
  const [provideOnHold] = useProvideOnHoldMutation();
  const setOnHold = (value: boolean) => {
    provideOnHold({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          onHold: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideOnHold: {
          __typename: 'WipSalesOrderProvideOnHoldPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'onHold',
                      formerValue: order.onHold,
                      date: new Date(),
                    },
                  ])
                : [],
            onHold: value,
          },
        },
      },
    });
  };

  return (
    <div
      style={{
        width: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
      }}
    >
      <Typography variant="h6">On Hold</Typography>
      <Checkbox
        disabled={
          order.status === WipStatus.Rejected || order.status === WipStatus.Sent
        }
        checked={order.onHold}
        color="primary"
        onChange={(e) => {
          setOnHold(e.target.checked);
        }}
        size="medium"
      />
    </div>
  );
};

export default OnHoldCheckbox;
