import React from 'react';
import { DisplayStyle } from './DisplayStyle';

const PdfDisplay: React.FC<{ url: string }> = ({ url }) => {
  return (
    <DisplayStyle>
      <embed
        width="100%"
        height="100%"
        id="pdf-display"
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
        type="application/pdf"
      />
    </DisplayStyle>
  );
};

export default PdfDisplay;
