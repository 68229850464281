import styled from 'styled-components';

export const DisplayStyle = styled.div`
  width: 100%;
  height: 600px;
  border: none;
  overflow-y: hidden;

  .center-message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
