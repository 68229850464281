import { Link } from 'react-router-dom';
import styled from 'styled-components';

export function NotFound() {
  return (
    <NotFoundStyle>
      <div>
        <div className="header">404 | Not Found</div>
        <Link to="/" className="home">
          Go Home
        </Link>
      </div>
    </NotFoundStyle>
  );
}

const NotFoundStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  text-align: right;

  .header {
    font-size: 2rem;
  }
  .home {
    font-size: 1.3rem;
    text-decoration: none;
    color: #3f7f79;
  }
`;
