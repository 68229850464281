import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import {
  useProvideInternalReferenceMutation,
  OrderQuery,
  WipStatus,
} from '../../generated/graphql';
import { optimisticMutation } from './optimistic';
import { Typography, FormControl, TextField } from '@mui/material';

export interface InternalReferenceProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const InternalReference: React.FC<InternalReferenceProps> = ({ order }) => {
  const [current, setCurrent] = useState<string | null>(null);
  const [provideInternalReference] = useProvideInternalReferenceMutation();
  const setInternalReference = (value: string) => {
    provideInternalReference(
      optimisticMutation({
        mutationName: 'wipSalesOrderProvideInternalReference',
        key: 'internalReference',
        value,
        order,
      }),
    );
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setInternalReference(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCurrent(val);
    doMutation(val);
  };

  return (
    <div>
      <Typography variant="h6">Reference</Typography>
      <FormControl style={{ width: 150 }} variant="standard">
        <TextField
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          value={current ?? order.internalReference ?? ''}
          onChange={onChange}
          variant="standard"
        />
      </FormControl>
    </div>
  );
};

export default InternalReference;
