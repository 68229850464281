
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FileInfo": [
      "DeletedFile",
      "PresentFile"
    ],
    "UpdateHandlesGetError": [
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError"
    ],
    "UploadHandlesGetError": [
      "UploadHandlesGetInvalidInputError"
    ],
    "UserError": [
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError",
      "UploadHandlesGetInvalidInputError"
    ],
    "WipMutationError": [
      "WipOptimisticLockingError"
    ],
    "WipSalesOrderSource": [
      "WipSalesOrderShopifySource"
    ]
  }
};
      export default result;
    