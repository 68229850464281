import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import Swal from 'sweetalert2';
import { SentryLink } from 'apollo-link-sentry';
import MutationQueueLink from '@adobe/apollo-link-mutation-queue';
import { setContext } from '@apollo/client/link/context';
import introspectionResult from '../../generated/introspection-result';
import { getAccessToken, authEnabled } from '../auth';
import { env } from '../../runtime-environment';

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    WipSalesOrder: {
      fields: {
        deliveryAddress: { merge: false },
        state: {
          // If we do a refetch query or for whatever reason a query happens
          // that updates the `state` on an order, we need to be careful to
          // ensure that that state is accurate. If that state is lower than the
          // existing state, then that implies that the data in the query
          // response was calculated on the backend prior to a mutation we've
          // already performed. (This can easily happen because we don't queue
          // queries sequentially like we do with mutations.) Since the state is
          // simply a number in string form that increases on each change, this
          // ensures we have the most up-to-date state we can. (If the state is
          // incremented by some other use, we won't know, and mutations will
          // fail with an appropriate error message to the user - that's the
          // point - preventing more than one user from modifying an order at
          // once).
          merge: (existing = '1', incoming: string) => {
            return Math.max(
              parseInt(existing, 10),
              parseInt(incoming, 10),
            ).toString();
          },
        },
      },
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = authEnabled ? await getAccessToken() : null;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.accessToken}` : '',
      // Requests for this specific app can be found in Apollo Studio
      'apollographql-client-name': 'saleshandler',
      'apollographql-client-version': env.version || 'local',
    },
  };
});

export const STATE_UNKNOWN = '__unknown__';

// Sometimes one mutation may be triggered before another mutation completes.
// While the `MutationQueueLink` ensures that this new mutation doesn't begin
// until the first one completes, the mutation may still be triggered with a
// stale `state` value for the sales order to modify - i.e. '__unknown__' from
// all our optimistic responses. This fetches the value from the cache when the
// mutation is actually sent (because it appears after `MutationQueueLink` in
// the link list), which will contain a valid state value, because any prior
// mutations will have at that point completed and updated the cache with the
// relevant sales order's state value.
//
// This link will also show any error messages
const stateLink = new ApolloLink((operation, forward) => {
  const input = operation.variables?.input;
  if (
    !['SendOrder', 'RejectOrder', 'DeleteOrder'].includes(
      operation.operationName,
    ) &&
    !input?.orderId &&
    input?.state !== STATE_UNKNOWN
  ) {
    return forward(operation);
  }

  const result = client?.cache.readFragment({
    id: `WipSalesOrder:${input.orderId ?? input.id}`,
    fragment: gql`
      fragment SOState on WipSalesOrder {
        state
      }
    `,
  });

  if (result) {
    input.state = (result as any).state;
  }

  return forward(operation).map((res) => {
    if (!res.data) return res;

    const errors = Object.keys(res.data)
      .map((key) => res.data![key]?.error)
      .filter((x) => x);
    for (const error of errors) {
      Swal.fire({
        icon: 'error',
        title: error.message ?? 'An error occurred within a mutation',
      });
    }

    // Reset state value so that the user cannot simply try again without
    // refreshing
    const newRes = { ...res, data: { ...res.data } };
    for (const key in newRes.data) {
      const obj = newRes.data[key];
      if (obj.error && obj.order) {
        obj.order = { ...obj.order, state: STATE_UNKNOWN };
      }
    }

    return newRes;
  });
});

const sentryLink = new SentryLink({
  uri: env.serverUri,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {},
});

let client: ApolloClient<NormalizedCacheObject> | null = null;
const makeApolloClient = () => {
  if (client) return client;

  const httpLink = createHttpLink({
    uri: env.serverUri,
  });

  client = new ApolloClient({
    link: ApolloLink.from([
      new MutationQueueLink({
        debug: true,
      }) as any,
      stateLink,
      authLink,
      sentryLink,
      httpLink,
    ]),
    cache,
  });
  return client;
};

export default makeApolloClient;
