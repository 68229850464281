import React from 'react';

import {
  OrderQuery,
  TaxType,
  useProvideTaxTypeMutation,
  WipStatus,
  WipType,
} from '../../generated/graphql';
import { Checkbox, Typography } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface GstInclusiveCheckboxProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const GstInclusiveCheckbox: React.FC<GstInclusiveCheckboxProps> = ({
  order,
}) => {
  const [provideTaxType] = useProvideTaxTypeMutation();
  const setTaxType = (value: TaxType) => {
    provideTaxType({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          taxType: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideTaxType: {
          __typename: 'WipSalesOrderProvideTaxTypePayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            modifications:
              order.type !== WipType.Manual
                ? order.modifications.concat([
                    {
                      __typename: 'FieldModification',
                      field: 'taxType',
                      formerValue: order.taxType,
                      date: new Date(),
                    },
                  ])
                : [],
            taxType: value,
          },
        },
      },
    });
  };

  return (
    <div
      style={{
        width: '180px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
      }}
    >
      <Typography variant="h6">GST Inclusive</Typography>
      <Checkbox
        disabled={
          order.orderDataFixed ||
          order.status === WipStatus.Rejected ||
          order.status === WipStatus.Sent
        }
        checked={order.taxType === TaxType.GstInclusive}
        color="primary"
        onChange={(e) => {
          setTaxType(
            e.target.checked ? TaxType.GstInclusive : TaxType.GstExclusive,
          );
        }}
        size="medium"
      />
    </div>
  );
};

export default GstInclusiveCheckbox;
