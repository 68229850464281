import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_MS } from '../../util';

import {
  useProvideCustomerPhoneNumberMutation,
  OrderQuery,
  WipStatus,
} from '../../generated/graphql';
import { Typography, FormControl, TextField } from '@mui/material';
import { STATE_UNKNOWN } from '../../services/apollo/setup';

export interface CustomerPhoneNumberProps {
  order: Exclude<OrderQuery['wipSalesOrder'], null | undefined>;
}

const CustomerPhoneNumber: React.FC<CustomerPhoneNumberProps> = ({ order }) => {
  const [current, setCurrent] = useState<string | null>(null);

  const [provideCustomerPhoneNumber] = useProvideCustomerPhoneNumberMutation();
  const setCustomerPhoneNumber = (value: string) => {
    provideCustomerPhoneNumber({
      variables: {
        input: {
          state: STATE_UNKNOWN,
          orderId: order.id,
          customerPhoneNumber: value,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        wipSalesOrderProvideCustomerPhoneNumber: {
          __typename: 'WipSalesOrderProvideCustomerPhoneNumberPayload',
          error: null,
          order: {
            __typename: 'WipSalesOrder',
            id: order.id,
            state: STATE_UNKNOWN,
            status: order.status,
            customerPhoneNumber: value,
          },
        },
      },
    });
  };

  const doMutation = useDebouncedCallback((value: string) => {
    setCustomerPhoneNumber(value);
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.toUpperCase();
    setCurrent(val);
    doMutation(val);
  };

  return (
    <div>
      <Typography variant="h6">Customer PhNo</Typography>
      <FormControl style={{ width: 250 }} variant="standard">
        <TextField
          disabled={
            order.status === WipStatus.Rejected ||
            order.status === WipStatus.Sent
          }
          multiline={true}
          placeholder={order.customer?.phoneNumber || ''}
          value={current ?? (order.customerPhoneNumber || '')}
          onChange={onChange}
          variant="standard"
        />
      </FormControl>
    </div>
  );
};

export default CustomerPhoneNumber;
