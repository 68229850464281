import React, { useState } from 'react';

import { Error } from '../Error';
import { ModificationDisplay } from '../ModificationDisplay';
import { InputUnderline } from '../InputUnderline';

import {
  useStockLocationsQuery,
  FieldModification,
} from '../../generated/graphql';
import { Select, Typography, MenuItem, LinearProgress } from '@mui/material';

export interface StockLocationPickerProps {
  noHeading?: boolean;
  blankText: string;
  stockLocationId: string | null;
  setStockLocationId: (id: string | null) => void;
  fixedValue: string | null;
  stockLevels?: Array<{ level: number; location: string }>;
  modifications: FieldModification[];
  selectStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}

const StockLocationPicker: React.FC<StockLocationPickerProps> = ({
  noHeading,
  blankText,
  stockLocationId,
  setStockLocationId,
  fixedValue,
  stockLevels,
  modifications,
  selectStyle,
  inputStyle,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { loading, error, data } = useStockLocationsQuery({
    fetchPolicy: 'cache-first',
  });

  const displayLoc = (loc?: { name: string }) =>
    loc ? (loc.name.split('.')[1] || loc.name).replace(' Stock', '') : '??';

  if (loading) return <LinearProgress />;
  if (error || !data) return <Error />;

  return (
    <ModificationDisplay
      modifications={modifications}
      processValue={(id: string | null) =>
        id === null
          ? null
          : displayLoc(data.stockLocations.find((x) => x.id === id))
      }
      tooltipOpen={tooltipOpen}
    >
      <InputUnderline enable={modifications.length > 0}>
        {!noHeading && <Typography variant="h6">Stock Location</Typography>}
        {fixedValue ? (
          <Typography>{displayLoc({ name: fixedValue })}</Typography>
        ) : (
          <Select
            style={{ width: 150, ...selectStyle }}
            inputProps={{ style: inputStyle }}
            value={stockLocationId || -1}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            onMouseDown={() => setTooltipOpen(false)}
            onChange={(e) =>
              setStockLocationId(
                e.target.value === -1 ? null : (e.target.value as string),
              )
            }
            variant="standard"
          >
            <MenuItem
              value={-1}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>{blankText}</span>
            </MenuItem>
            {data.stockLocations
              .filter(({ id }) => !['3', '5'].includes(id))
              .map((loc) => {
                const level = stockLevels?.find(
                  (x) => x.location === loc.id,
                )?.level;
                return (
                  <MenuItem
                    key={loc.id}
                    value={loc.id}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{displayLoc(loc)}</span>
                    {open && level != null && (
                      <span
                        style={{
                          transform: 'translateX(10px)',
                          fontSize: 11,
                          alignSelf: 'flex-end',
                        }}
                      >
                        {level}
                      </span>
                    )}
                  </MenuItem>
                );
              })}
          </Select>
        )}
      </InputUnderline>
    </ModificationDisplay>
  );
};

export default StockLocationPicker;
