import React from 'react';
import HtmlDisplay from './HtmlDisplay';
import PdfDisplay from './PdfDisplay';
import TextDisplay from './TextDisplay';
import { Button, Typography } from '@mui/material';
import { OrderQuery } from '../../generated/graphql';
import styled from 'styled-components';

export type OriginalSource = NonNullable<
  OrderQuery['wipSalesOrder']
>['originalSources'][0];

export interface DocumentDisplayProps {
  originalSource: OriginalSource;
}

const DocumentDisplay: React.FC<DocumentDisplayProps> = ({
  originalSource,
}) => {
  let button;
  if (originalSource.file.info.__typename === 'PresentFile') {
    button = (
      <Button
        component="a"
        href={originalSource.file.info.accessUrl}
        target="_blank"
      >
        Open in new tab
      </Button>
    );
  }

  return (
    <DocumentWrapper>
      <div className="heading">
        <span>
          <Typography variant="h3" className="title">
            {originalSource.file.name}
          </Typography>
        </span>
        <span>{button}</span>
      </div>
      <div>
        <DocumentDisplayInner originalSource={originalSource} />
      </div>
    </DocumentWrapper>
  );
};

const DocumentDisplayInner: React.FC<DocumentDisplayProps> = ({
  originalSource,
}) => {
  if (originalSource.file.info.__typename === 'DeletedFile') {
    return <Typography>File has been deleted</Typography>;
  }

  const { contentType, info } = originalSource.file;

  if (contentType === 'application/pdf') {
    return <PdfDisplay url={info.accessUrl} />;
  }

  if (contentType === 'text/html') {
    return <HtmlDisplay url={info.accessUrl} />;
  }

  // Otherwise show as text
  return <TextDisplay url={info.accessUrl} />;
};

const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgb(204 204 204) 0px 0px 5px;

  .heading {
    background: #fff;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 1rem;
      opacity: 0.54;
    }

    border-bottom: 2px solid #e0e0e0;
  }

  span {
    padding: 1em;
  }

  a {
    height: 2rem;
  }

  div {
    justify-content: flex-start;
  }
`;

export default DocumentDisplay;
